import * as Msal from 'msal';

class MsalApi {
    static CLIENT_ID = "8b7a0990-8b38-4697-91d6-c916f3a2e88c";

    static application() {
        var msalConfig = {
            auth: {
                clientId: MsalApi.CLIENT_ID,
                authority: "https://login.microsoftonline.com/organizations",
                redirectUri: 'https://flowsync.se/InstanceAuthCallback'
            },
            cache: {
                cacheLocation: "localStorage",
                storeAuthStateInCookie: true
            }
        };

        return new Msal.UserAgentApplication(msalConfig);
    }

    static idTokenSilent() {
        const application = MsalApi.application();

        var request = {
            scopes: [MsalApi.CLIENT_ID],
            state: window.location.origin
        };

        return application.acquireTokenSilent(request);
    }

    static idTokenPopup() {
        const application = MsalApi.application();

        var request = {
            scopes: [MsalApi.CLIENT_ID],
            state: window.location.origin
        };

        return application.loginPopup(request);
    }

    static logout() {
        const application = MsalApi.application();
        return application.logout();
    }
}

export default MsalApi;