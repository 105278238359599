import React, { Component } from 'react';
import NavMenu from './NavMenu';
import SubFooter from './SubFooter';
import { connect } from 'react-redux';
import { silentAuth } from './store/AuthAction';

export class Layout extends Component {

    componentDidMount = async () => {
        if (this.props.user === undefined) {
            await this.props.silentAuth();
        }
    };

    render() {
        return (
            <div className="layout">
                <NavMenu />
                <div className="body">
                    {this.props.children}
                </div>
                <SubFooter />
            </div>
        );
    }
}

const mapStateToProps = state => ({
    user: state.auth.userAuthorized
});

export default connect(mapStateToProps, { silentAuth })(Layout);
