import React, { useState, useEffect } from 'react';
import { Button, Container, Table } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Loading from './misc/Loading';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { getCustomerPermissions, updateGroupCustomerPermission, updateUserCustomerPermission, getCustomerPermissionsLevels } from './store/CustomerPermissionsAction'
import { GetInstance } from './store/InstanceAction';
import RemoveCustomerUser from './modules/RemoveCustomerUser';
import EditCustomerUser from './modules/EditCustomerUser';
import AddCustomerUser from './modules/AddCustomerUser';
import * as PermissionLevel from './util/PermissionLevelUtil';
import StatusCode from './util/StatusCode';


const CustomerUserPermissions = () => {
    const { customerId } = useParams();
    const dispatch = useDispatch();

    const [savingState, setSavingState] = useState({});
    const [remove, setRemove] = useState(false);
    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [user, setUser] = useState(undefined);

    useEffect(() => {
        Promise.all([
            GetInstance(customerId)(dispatch),
            getCustomerPermissionsLevels(customerId)(dispatch),
            getCustomerPermissions(customerId)(dispatch),
        ]).then(() => setSavingState({ status: StatusCode.NONE }))
    }, []);

    const me = useSelector(state => state.auth.userAuthorized);
    const customerUsers = useSelector(state => state.customerPermission.customerUsers);
    const customer = useSelector(state => state.instance.instance);
    const permissionLevels = useSelector(state => state.customerPermission.permissionLevels);

    const updateUser = async (items, allowUpdate) => {
        setSavingState({ status: StatusCode.PENDING });

        const combinedSavingState = { status: StatusCode.COMPLETE };
       
        if (items.users && items.users.length) {
            const res = await updateUserCustomerPermission(customerId, items.users, allowUpdate)(dispatch);
            if (res.status === StatusCode.ERROR) {
                combinedSavingState.status = StatusCode.ERROR;
            }
            combinedSavingState.users = res;
        } else {
            combinedSavingState.users = { status: StatusCode.COMPLETE }
        }

        if (items.groups && items.groups.length) {
            let res = await updateGroupCustomerPermission(customerId, items.groups, allowUpdate)(dispatch);
            if (res.status === StatusCode.ERROR) {
                combinedSavingState.status = StatusCode.ERROR;
            }
            combinedSavingState.groups = res;
        } else {
            combinedSavingState.groups = { status: StatusCode.COMPLETE }
        }

        setSavingState(combinedSavingState)
    }

    const toggleModal = () => {
        if (savingState.status !== StatusCode.NONE) {
            getCustomerPermissions(customerId)(dispatch);
        }
        setRemove(false);
        setAdd(false);
        setEdit(false);
        setSavingState({ status: StatusCode.NONE });
    }

    const renderAddUser = () => {
        return (
            <AddCustomerUser
                isOpen={add}
                toggleModal={() => toggleModal()}
                customer={customer.data}
                update={(item) => updateUser(item, false)}
                disabled={false}
                savingState={savingState}
                clearState={() => setSavingState({ status: StatusCode.NONE })}
            />
        )
    }

    const renderRemoveUser = () => {
        return (
            <RemoveCustomerUser
                user={user}
                isOpen={remove}
                toggleModal={() => toggleModal()}
                update={(item) => updateUser(item, true)}
                disabled={false}
                savingState={savingState}
            />
        )
    }

    const renderEditUser = () => {
        return (
            <EditCustomerUser
                user={user}
                isOpen={edit}
                toggleModal={() => toggleModal()}
                disabled={false}
                id={customerId}
                update={(item) => updateUser(item, true)}
                savingState={savingState}
            />
        )
    }

    const renderTableRows = (permissions, edit, canEditCustomer) => {
        if (permissions.length > 0) {
            return permissions.map(p => <tr key={p.id}>
                <td data-label="Namn">{p.displayName}</td>
                <td data-label="Email">{p.email ? p.email : '-'}</td>
                <td data-label="Behörigheter">{p.roleName.join(', ')}</td>
                {canEditCustomer && <td data-label="Uppdatera behörigheter" title="Redigera behörigheter" className="edit-user" onClick={() => editUser(p)}><FontAwesomeIcon icon="pen" /></td>}
                {PermissionLevel.canRemoveCustomerPermission(permissionLevels, p) && <td data-label="Radera behörigheter" title="Radera behörigheter" className="edit-user" onClick={() => removeUser(p)}><FontAwesomeIcon icon="trash" /></td>}
            </tr>);
        } else {
            return <tr key="missing_permission"><td>Inga tilldelade behörigheter finns för denna instans.</td></tr>;
        }
    }

    const editUser = (p) => {
        setEdit(true);
        setUser(p);
    }

    const removeUser = (p) => {
        setRemove(true);
        setUser(p);
    }

    const renderCustomerUsersTable = () => {
        const canEditCustomer = PermissionLevel.canEditCustomer(me, customerId)

        if (!customerUsers)
            return <Loading />
        if (customerUsers === 'error')
            return 'Kunde inte hämta behörigheter'
        return (<React.Fragment>
            <p className="title">{customerUsers.title}</p>
            <Table className="user_permissions-table">
                <thead>
                    <tr>
                        <th colSpan="2">Användare</th>
                        <th>Behörighetsnivå</th>
                        {canEditCustomer && <th colSpan="2">Redigera</th>}
                    </tr>
                </thead>
                <tbody>
                    {renderTableRows(customerUsers, edit, canEditCustomer)}
                </tbody>
            </Table>
            {canEditCustomer && <Button className="btn-primary add_user-btn" onClick={() => setAdd(!add)}>Lägg till användare eller grupp</Button>}
        </React.Fragment>);
    }

    return (
        <div className="page permissions">
            <div>
                <Container>
                    <h1>Behörigheter</h1>
                    <p className="title">{customer.status === StatusCode.COMPLETE && customer.data.title}</p>
                </Container>
            </div>
            <Container>
                {renderCustomerUsersTable()}

                {remove && renderRemoveUser()}
                {edit && renderEditUser()}
                {add && renderAddUser()}

            </Container >
        </div >
    );
}

export default CustomerUserPermissions;
