import React, { useState, useEffect } from 'react';
import { Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, Input, FormGroup, Card, CardHeader, Collapse, CardBody } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../misc/Loading';
import { getCustomerPermissionsLevels } from '../store/CustomerPermissionsAction';
import * as PermissionLevel from '../util/PermissionLevelUtil';
import StatusCode from '../util/StatusCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddUser from './AddUser';
import { createCustomerPermissionUpdate } from '../util/CustomerPermissionHelper';
import { useParams } from 'react-router-dom';


const AddCustomerUser = (props) => {

    const { customerId } = useParams();
    const dispatch = useDispatch();

    const permissionLevels = useSelector(state => state.customerPermission.permissionLevels);
    const me = useSelector(state => state.auth.userAuthorized);

    const [selectedItems, setSelectedItems] = useState([]);
    const [open, setOpen] = useState(true);
    const [updatedPermissionLevels, setPermissionLevels] = useState([]);

    useEffect(() => {
        getCustomerPermissionsLevels(customerId)(dispatch);
    }, [dispatch]);

    const createNewCustomerPermission = () => {
        const updates = createCustomerPermissionUpdate(selectedItems, updatedPermissionLevels);
        props.update(updates)
    }

    const onChangePermission = (e) => {
        const value = e.target.name;
        const updates = [...updatedPermissionLevels];

        var index = updates.indexOf(value);
        if (index > -1) {
            updates.splice(index, 1)
        } else {
            updates.push(value)
        }
        setPermissionLevels(updates)
    }

    const renderSavingState = () => {
        const { savingState } = props;
        if (savingState.status === StatusCode.PENDING) {
            return <Loading class='spinnerSmall' />
        }
        if (savingState.status === StatusCode.COMPLETE) {
            return <p><FontAwesomeIcon icon="check-circle" className="saving-icon ok" />Sparat</p>
        }
        if (savingState.status === StatusCode.ERROR) {
            const groupConflict = savingState.groups.status === StatusCode.ERROR && savingState.groups.error.response.status === 409;
            const userConflict = savingState.users.status === StatusCode.ERROR && savingState.users.error.response.status === 409;

            if (groupConflict && userConflict) {
                return (<p><FontAwesomeIcon icon="xmark-circle" className="saving-icon error" />En eller flera användare eller grupper finns redan. Redigera behörigheterna via behörighetslistan.</p>);
            } else if (userConflict) {
                return (<p><FontAwesomeIcon icon="xmark-circle" className="saving-icon error" />En eller flera användare finns redan. Redigera behörigheterna via behörighetslistan.</p>);
            } else if (groupConflict) {
                return (<p><FontAwesomeIcon icon="xmark-circle" className="saving-icon error" />En eller flera grupper finns redan. Redigera behörigheterna via behörighetslistan.</p>);
            } else {
                return <p>Något gick fel! Kunde inte spara</p>
            }
        }
    }

    const renderPermissionLevels = () => {
        return (
            <Card style={{ marginTop: '1.25rem' }}>
                <CardHeader tag="h5">
                    <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => setOpen(!open)}>{open ? '-' : '+'}</Button>
                    Behörighet
                </CardHeader>
                <Collapse isOpen={open}>
                    <CardBody>
                        {permissionLevels && permissionLevels.map(pl =>
                            <FormGroup check key={pl}>
                                <Label check>
                                    <Input type="checkbox" name={pl} defaultChecked={updatedPermissionLevels.indexOf(pl) !== -1} onChange={onChangePermission} disabled={PermissionLevel.disabledPermission(pl, me)} />
                                    {pl}
                                </Label>
                            </FormGroup>
                        )}
                    </CardBody>
                </Collapse>
            </Card>
        )
    }

    const renderUsers = () => {
        return (
            <AddUser
                customer={props.customer}
                selectedItems={(selectedItems) => setSelectedItems(selectedItems)}
                selectGroups={true}
                clearState={props.clearState}
                showSavingState={true}
            />);
    }


    const disableSave = (selectedItems.length === 0 || updatedPermissionLevels.length === 0);

    return (
        <Modal className="lg add-user-modal" isOpen={props.isOpen} toggle={props.toggleModal}>
            <ModalHeader toggle={props.toggleModal}>
                Lägg till användare eller grupp
            </ModalHeader>
            <ModalBody>
                {renderUsers()}
                {renderPermissionLevels()}
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggleModal}>Avbryt</Button>
                <Button className="btn-primary" disabled={disableSave || props.disabled} onClick={createNewCustomerPermission}>Lägg till</Button>
                {renderSavingState()}
            </ModalFooter>
        </Modal>
    );

}

export default AddCustomerUser;
