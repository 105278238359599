const parseAuthObjectFromQuery = () => {
    if (!window.location.search.length) return [];

    var searches = window.location.search.substring(1).split("&");
    var obj = {};
    searches.forEach(search => {
        var split = search.split("=");
        if (split.length < 2) return;
        const key = decodeURIComponent(split[0]);
        const value = decodeURIComponent(split[1])
        obj[key] = value;
    });
    return obj;
};

export const handleAuthWindow = () => {
    if (window.location.pathname === '/authorizationCallback' && window.opener) {
        var query = parseAuthObjectFromQuery();
        window.opener.postMessage({
            messageType: 'authorizationCallback',
            ...query
        });
        window.close();
    }
};

export const authWindow = (url) => {
    return new Promise((resolve, reject) => {
        var handler = (e) => {
            if (e && e.data && e.data.messageType && e.data.messageType === 'authorizationCallback') {
                window.removeEventListener('message', handler);
                delete e.data.messageType;
                resolve(e.data);
            }
        };

        window.addEventListener('message', handler);
        window.open(url);
    });
};