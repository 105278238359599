import React, { useState } from 'react';
import { Collapse, Navbar, NavbarBrand, NavbarToggler, NavItem, NavLink, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';
import SignInModal from './SignInModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PermissionLevel from './util/PermissionLevelUtil';
import { signOut } from './store/AuthAction';


const NavMenu = () => {
    const [collapsed, setCollapsed] = useState(true);
    const [isOpen, setIsOpen] = useState(false);
    const needLogin = useSelector(state => state.auth.needLogin);
    const user = useSelector(state => state.auth.userAuthorized);
    const authAuthority = useSelector(state => state.auth.authAuthority);
    const dispatch = useDispatch();

    return (
        <header>
            <Navbar className="navbar-expand-lg transparent navbar-toggleable-lg ">
                <div className="mobile-menu">
                    <NavbarBrand tag={Link} to="/">
                        <img src="/FlowSync_lg_light.svg" alt="" className="logo" />
                    </NavbarBrand>
                    <NavbarToggler onClick={() => setCollapsed(!collapsed)} className="mr-2" >{collapsed ? <FontAwesomeIcon icon="bars" /> : <FontAwesomeIcon icon="xmark" />}</NavbarToggler>
                </div>

                <Collapse className="md-column d-lg-inline-flex flex-lg-row-reverse" isOpen={!collapsed} navbar>
                    <ul className="navbar-nav flex-grow">

                        <div className="md-half">
                            {!needLogin && <>
                                {user && PermissionLevel.canEditGroupPermission(user) && <>
                                    <UncontrolledDropdown nav inNavbar>
                                        <DropdownToggle nav caret>
                                            Administrera
                                        </DropdownToggle>
                                        <DropdownMenu end>
                                            <DropdownItem>
                                                <NavLink tag={Link} to="/permissiongroups">Behörighetsgrupper</NavLink>
                                            </DropdownItem>
                                            {(user && PermissionLevel.isAdmin(user)) &&
                                                <DropdownItem>
                                                    <NavLink tag={Link} to="/consumers">API Konsumenter</NavLink>
                                                </DropdownItem>}
                                        </DropdownMenu>
                                    </UncontrolledDropdown>
                                </>}
                                {user && (user.customerPermissionLevels?.length > 0 || PermissionLevel.isAdmin(user)) && 
                                    <NavItem>
                                        <NavLink tag={Link} to="/instances">Instanser</NavLink>
                                    </NavItem>
                                }
                            </>}

                            <NavItem>
                                <NavLink tag={Link} to="/about">Om</NavLink>
                            </NavItem>
                        </div>

                        <div className="md-half">
                            {needLogin &&
                                <NavItem className="user-menu-item">
                                    <div className="nav-link log-out-icon" onClick={() => setIsOpen(!isOpen)}><FontAwesomeIcon icon="right-to-bracket" className="icon" /> Logga in</div>
                                </NavItem>}

                            {!needLogin &&
                                <UncontrolledDropdown nav inNavbar className="user-menu-item">
                                    <DropdownToggle nav className="user-menu-icon">
                                        <div><FontAwesomeIcon icon="user" className="icon" /></div>
                                    </DropdownToggle>
                                    <DropdownMenu className="user_dropdown">
                                        <DropdownItem>
                                            <div className="user_info">
                                                <div>{user?.displayName}</div>
                                                <div>{user?.email}</div>
                                                <div>{user?.permissionLevels?.join(', ')}</div>
                                            </div>
                                        </DropdownItem>
                                        <DropdownItem className="user-menu-logout">
                                            <NavLink href="#" onClick={() => signOut(authAuthority)(dispatch)}><FontAwesomeIcon icon="right-from-bracket" className="icon" />Logga ut</NavLink>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </UncontrolledDropdown>}
                        </div>

                        {isOpen && needLogin &&
                            <SignInModal
                                header='Logga in'
                                isOpen={isOpen}
                                toggleModal={() => setIsOpen(!isOpen)}
                            />}

                    </ul>
                </Collapse>
            </Navbar>
        </header>
    );
}

export default NavMenu;
