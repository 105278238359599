import React from 'react';
import { Nav, NavItem, NavLink } from 'reactstrap';
import { Link } from 'react-router-dom';
import './Help.css';


const menuItems = [
    { url: "/help/edlevo/activities", text: "Samarbetsytor i Edlevo" },
    { url: "/help/localad/general", text: "Konfiguration Lokalt AD" },
    { url: "/help/schooladmin/primarySchool", text: "Primär skola" },
    { url: "/help/digitala-nationella-prov", text: "Digitala Nationella Prov" },
];

const HelpMenu = () => {
    return (
        <Nav vertical="sm" className="guides-menu">
            <ul className="navbar-nav flex-grow">
                {menuItems.map((m, i) => (
                    <NavItem key={i}>
                        <NavLink tag={Link} to={m.url}>{m.text}</NavLink>
                    </NavItem>
                ))}
            </ul>
        </Nav>
    );
}

export default HelpMenu;
