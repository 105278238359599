import React, { ReactNode } from 'react';
import { Table } from 'reactstrap';
import Loading from './Loading';

interface InfoObject {
    id: string
    data: { value: string | ReactNode, className?: string }[]
}

interface TableHorizontalProps {
    error: boolean
    header: { value: string | ReactNode, colSpan?: number }[]
    searching: boolean
    info: InfoObject[]
    noResMsg?: string
}

const TableHorizontal: React.FC<TableHorizontalProps> = (props: TableHorizontalProps) => {
    return (
        <div>
            <Table className="overwiew-table">
                <thead>
                    <tr>
                        {props.header.map((h, i) =>
                            <th key={i} colSpan={h.colSpan ?? h.colSpan}>{h.value}</th>
                        )}
                    </tr>
                </thead>
                <tbody>
                    {props.info && props.info.map(row =>
                        <tr className="list_table" key={row.id}>
                            {row.data.map((d, i) => {
                                const dataLabel = d.value ? props.header[i].value : null;
                                return (
                                    <td key={i} data-label={dataLabel} className={d.className}>{d.value}</td>
                                )}
                            )}
                        </tr>
                    )}
                </tbody>
            </Table>
            {props.searching && <Loading class='spinnerSmall' />}
            {props.error && <p>Kunde inte hämta</p>}
            {!props.error && !props.searching && !props.info.length && !props.noResMsg && <p>Resultat saknas</p>}
            {!props.error && !props.searching && !props.info.length && props.noResMsg && <p>{props.noResMsg}</p>}
        </div>
    )
}

export default TableHorizontal;
