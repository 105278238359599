import React, { Component } from 'react';
import { connect } from 'react-redux';
import { silentAuth } from './store/AuthAction';
import Loading from './misc/Loading';
import Login from './Login';
import UnAuthorized from './UnAuthorized';


class Private extends Component {

    render() {
        const { needLogin, user, children } = this.props;

        if (needLogin) {
            return <Login />;
        } else if (user === undefined) {
            return <Loading style={{ padding: '40px' }} />;
        } else if (!user.permissionLevels) {
            return <UnAuthorized />
        }
        return children;
    }
};

const mapStateToProps = state => ({
    needLogin: state.auth.needLogin,
    user: state.auth.userAuthorized
});

export default connect(mapStateToProps, { silentAuth })(Private);
