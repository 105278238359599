import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SchoolAdminPrimarySchool = () => {
    return (
        <div>
            <h1>Primär skola</h1>
            <p>FlowSync kan hantera att en person är kopplad till flera skolor och i vissa fall behöver personen tillhöra en skola. För att lösa detta beräknar FlowSync alltid en beräknad primär skola.
            </p>

            <h3>När används primär skola</h3>
            <p>Ett vanligt användningsfall där en person endast får vara kopplad till en skola är placeringar i ett OU i en katalog tjänst eller för visningsnamn i Google.</p>

            <h3>Val av primär skola</h3>
            <p>I vissa fall stämmer inte den beräknade primära skolan överrens med den verkligheten och då kan man alltid välja en ny primär skola.
                När man väljer en ny primär skola så framtvingar man att den skolan alltid väljs så länge personen fortfarande är kopplad till skolan.
                När personen inte längre är kopplad till den valda primära skolan så återgår system till den beräknade primära skolan.
            </p>

            <h3>Rensa val av primär skola</h3>
            <p>Om vald primär skola inte längre är aktuell kan man rensa bort kopplingen och återgå till att låta FlowSync beräkna primär skola.</p>

            <h3>Teckenförklaring</h3>
            <p>
                <FontAwesomeIcon key='grey' className="me-2" color="grey" icon="check" /> Beräknad primär skola<br/>
                <FontAwesomeIcon key='green' className="me-2" color="orange" icon="crown" /> Vald primär skola
            </p>
        </div>
    )
}

export default SchoolAdminPrimarySchool;
