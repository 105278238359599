import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { setServiceAccount, GetInstance } from '../store/InstanceAction';
import ConsentGoogle from './ConsentGoogle';
import ConsentMicrosoft from './ConsentMicrosoft';


class ConsentModal extends Component {

    renderBody() {
        const type = this.props.data.appTokenType;
        if (type === 1) {
            return (
                <ConsentMicrosoft
                    data={this.props.data}
                    disabled={this.props.disabled}
                    toggleModal={() => this.props.toggleModal()}
                    disabled={this.props.disabled}
                />);
        }
        if (type === 3) {
            return (
                <ConsentGoogle
                    customer={this.props.customer}
                    data={this.props.data}
                    disabled={this.props.disabled}
                />);
        }
    }

    render() {
        return (
            <Modal size="lg" isOpen={this.props.isOpen} toggle={this.props.toggleModal}>
                <ModalHeader toggle={this.props.toggleModal}>
                    Åtkomst
                </ModalHeader>
                <ModalBody>
                    {this.renderBody()}
                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.props.toggleModal}>Stäng</Button>
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    customerProps: state.instance.instance,
});

export default connect(mapStateToProps, { setServiceAccount, GetInstance })(ConsentModal);
