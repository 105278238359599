import React from 'react';

const SubFooter = () => {
    return (
        <div className="subfooter">
            <p>En produkt av</p>
            <a href="https://www.eventful.se/" target="_blank"><img src="https://eventfulcdn.blob.core.windows.net/flowsync/eventful_logo.svg" alt="Eventful" /></a>
        </div>
    )
}

export default SubFooter;