import React from 'react';

const scrollTo = (ref) => {
    if (!ref) {
        return;
    } 

    const hash = '#' + ref.id;

    if (hash === window.location.hash) {
        ref.scrollIntoView();
    }
}

const DnpHelp = () => {
    return (
        <div>
            <h2 id="konfiguration-basmodul">Konfiguration Basmodul</h2>
            <h3 id="aktiv">Aktiv</h3>
            <p>Flagga som anger om modulen ska vara aktiv eller inte. Det går endast att köra en aktiv modul. Även om en
                inaktivmodul har schemaläggning så kommer den aldrig att starta.</p>
            <h3 id="dryrun">DryRun</h3>
            <p>Flagga som anger om modulen ska köras i DryRun-läge. DryRun-läge innebär att ingenting skickas till målsystemet utan
                synken genererar en rapport, oftast i form av csv-filer, med vad som kommer hända i målsystemet när synken körs.
            </p>
            <h3 id="titel">Titel</h3>
            <p>Fritextfält för modulens titel. Det du anger här kommer att synas i listvyn för moduler. </p>
            <h3 id="schemalaggning">Schemaläggning</h3>
            <p>Detta tillåter dig att sätta upp regler kring när och hur modulen ska starta automatiskt. Du kan antingen ange en
                tidpunkt via ett cronuttryck eller att modulen ska starta direkt efter att en annan modul har körts klart.</p>
            <h4 id="tidsbaserad-schemal-ggning">Tidsbaserad schemaläggning</h4>
            <p>Vid tidsbaserad schemaläggning anger du ett crontuttryck för när modulen ska starta. Läs mer om cronuttryck och testa
                att generera dem här: <a href="https://crontab.guru/">https://crontab.guru/</a>.</p>
            <p>Notera att tiden måste anges i tidszonen UTC.</p>
            <p>Schemaläggningen kan inte anges oftare än en gång i timmen.</p>
            <h4 id="modulbaserat-schemal-ggning">Modulbaserat schemaläggning</h4>
            <p>Vid modulbaserad schemaläggning så väljer du en modul att starta efter. När den utpekade modulen har körts klart så
                kommer den modulen du konfigurerar att starta.</p>
            <p>Detta endast gäller schemalagda körningar. Om modulen du pekar ut startas manuellt kommer nästa modul inte att
                starta.</p>
            <h2 id="konfiguration-digitala-nationella-prov" ref={scrollTo} className="help-scrollable">Konfiguration Digitala Nationella Prov</h2>
            <h3 id="organisationnummer">Organisationnummer</h3>
            <p>Huvudmannens organisationsnummer, anges med tolv siffror utan bindesstreck.</p>
            <p>Organisationnummret slås upp och valideras mot skolverket direkt.</p>
            <h3 id="malmiljo">Målmiljö</h3>
            <p>Anger om du ska synkronisera till Skolverkets produktionsmiljö eller verifieringstestmiljö. Påverkar både synk och
                kopplingsgränssnitt.</p>
            <h3 id="koppla-skolenheter">Koppla skolenheter</h3>
            <p>En lista på skolenheter enligt skolverket. Ange en eller flera skolor du vill skicka data ifrån. Denna inställning
                påverket aktivitetskopplingsgränssnittet i form av att du endast ser flowsync-aktiviteter från dessa skolor.</p>
            <p>Rektorer från de valda skolorna kommer att synkas till skolverket.</p>
            <h3 id="metadata-for-moa" ref={scrollTo} className="help-scrollable">Metadata för Moa</h3>
            <p>Hämtar en metadata-fil som man kan ladda upp till Moa (Skolfederation). Denna fil måste laddas upp innan det går att
                köra skarpt mot verifieringstest- och produktionsmiljö. Ny metadata-fil behöver endast laddas upp om organisationsnummer, certifikat eller mallen för metadata har uppdaterats.</p>
            <h2 id="aktivitetskoppling" ref={scrollTo} className="help-scrollable">Aktivitetskoppling</h2>
            <p>
                Eftersom alla aktiviteter i flowsync inte ska skriva prov och det finns inte någon koppling mellan aktivitet i flowsync och provaktivitet från Skolverket så måste den kopplingen hanteras manuellt.
                Den kopplingen administreras i vyn aktivitetskoppling som du kommer till från konfigurationssidan för dnp-modulen.
            </p>
            <p>
                Vyn är uppdelad i tre flikar (Kopplade aktiviteter, Ej kopplade aktiviteter och Felaktigt kopplade aktiviteter) vilka alla listar aktiviteter från flowsync och tillåter dig att välja en eller flera provaktiviteter som de ska kopplas ihop med.
            </p>
            <h3 id="filter">Filter</h3>
            <p>De filter som finns är uppdelade i olika riktningar. Antingen så filtrerar man mot FlowSync eller Skolverket.</p>
            <h4 id="flowsync">FlowSync</h4>
            <p>FlowSync-filtren styr vilka aktiviteter som kommer att visas i tabellen i vardera fil. Det finns möjlighet att
                filtrera på följande:</p>
            <ul>
                <li>Sök (fritext): Söker på aktivitetens namn och kursplanens kurs eller ämne. Mellanslag separerar två olika
                    sökningar som appliceras i kombination (logiskt och).</li>
                <li>Ämne: Välj ett ämne.</li>
                <li>Kurs: Välj en kurs.</li>
                <li>Skola: Välj vilken av de markerade skolorna du vill ha aktiviteter ifrån.</li>
            </ul>
            <h4 id="skolverket">Skolverket</h4>
            <ul>
                <li>Skolform: Visa bara provaktiviteter med vald skolform.</li>
                <li>Årskurs: Visa endast provaktiviteter med vald årskurs.</li>
            </ul>
            <h3 id="kopplade-aktiviteter">Kopplade aktiviteter</h3>
            <p>I fliken kopplade aktiviteter ser du aktiviteter som du har kopplat till en provaktivitet. Här kan du ta bort
                kopplingen och/eller ändra vilka provaktiviteter som aktiviteten ska vara kopplad från.</p>
            <h3 id="ej-kopplade-aktiviteter">Ej kopplade aktiviteter</h3>
            <p>I fliken ej kopplade aktiviteter ser du aktiviteter som du inte har kopplat till en provaktivitet. Här kan du välja
                att koppla nya aktiviteter till ett eller flera provaktiviteter.</p>
            <h3 id="felaktigt-kopplade-aktiviteter">Felaktigt kopplade aktiviteter</h3>
            <p>I fliken felaktigt kopplade aktiviteter ser du aktivitetskopplingar som synken har bedömt vara felaktiga. När dessa
                rättas till så kommer aktiviteten att flyttas till &quot;kopplade aktiviteter&quot; eller &quot;ej kopplade
                aktiviteter&quot; beroende på din förändring.</p>
            <p>En aktivitet kan t.ex. hamna i felaktigt kopplade aktiviteter när ett provtillfälle försvinner ifrån skolverket.</p>
            <h2 id="faq">FAQ</h2>
            <h3 id="hur-hanteras-elever-som-tillhor-en-annan-arskurs-an-den-for-provaktiviteten-gallande-arskursen">Hur hanteras
                elever som tillhör en annan årskurs än den för provaktiviteten gällande årskursen?</h3>
            <p>Synken skickar den årskurs som eleven är inskriven på enligt elevregistret. Skolverket tar inte emot elever som är
                inskrivna på årskurser som inte är aktiverade för prov.</p>
        </div>
    )
}

export default DnpHelp;
