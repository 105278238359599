import React, { Component } from 'react';
import { Col, Row, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'react-router-dom';
import SignInModal from './SignInModal';
import { connect } from 'react-redux';


class Home extends Component {
    state = {
        isOpen: false
    }

    render() {
        const { isOpen } = this.state;
        const { needLogin } = this.props;
        return (
            <div className="home">
                <div className="hero">
                    <Container>
                        <div className="text">
                            <h1>Välkommen till FlowSync</h1>
                            <h2>Din synkroniseringsmotor för all data inom skola</h2>
                            <p>FlowSync är vår produkt för nästa generation av Eventful SkolSynk. En version som är helt webb-baserad vilket ger er,
                                våra kunder, en unik möjlighet till insikt i vilken information som flödar i integrationen. Detta ger våra kunder
                                inblick i informationens väg från elevregister till skolplattform.</p>
                            <div className="btn-box">
                                <Link to="/about" className="btn">Mer information</Link>
                                {needLogin &&
                                    <div className="btn" onClick={() => this.setState({ isOpen: !isOpen })}> Logga in</div>
                                }
                            </div>
                        </div>
                    </Container>
                </div>

                <Container>

                    <Row className="icon-boxes">
                        <Col sm="4">
                            <span className="icon-box"><FontAwesomeIcon icon="thumbs-up" className="icon" /></span>
                            <h4>Enkelhet</h4>
                            <p>Vi gör det enklare att hantera era integrationer genom att erbjuda ett gränssnitt för integrationen</p>
                        </Col>
                        <Col sm="4">
                            <span className="icon-box"><FontAwesomeIcon icon="folder-open" className="icon" /></span>
                            <h4>Transparens</h4>
                            <p>Vi synliggör informationen på ett sätt som gör det lätt för skolan att se var informationen kommer från samt vart den skickas</p>
                        </Col>
                        <Col sm="4">
                            <span className="icon-box"><FontAwesomeIcon icon="rocket" className="icon" /></span>
                            <h4>Snabbhet</h4>
                            <p>Vi ser till att ni snabbare kan få igenom förändringar genom hela kedjan så att era pedagoger snabbt har tillgång till rätt information</p>
                        </Col>
                    </Row>
                </Container>

                {isOpen && needLogin &&
                    <SignInModal
                        header='Logga in'
                        isOpen={isOpen}
                        toggleModal={() => this.setState({ isOpen: !isOpen })}
                    />}
            </div>
        );
    }
}

const mapStateToProps = state => ({
    needLogin: state.auth.needLogin
});

export default connect(mapStateToProps, { })(Home);
