export const canEditGroupPermission = (me) => {
    if (me.permissionLevels.includes('Admin')) {
        return true;
    }
    if (me.customerPermissionLevels.some(x => x.permissionLevels.some(y => y === 'InstanceAdmin'))) {
        return true;
    }
    return false;
}

export const canEditCustomer = (me, customerId) => {
    if (me.permissionLevels.includes('Admin')) {
        return true;
    }

    const customerPermissions = me.customerPermissionLevels.find(x => x.customerId === customerId);
    if (customerPermissions) {
        return customerPermissions.permissionLevels.includes('InstanceAdmin')
    }

    return false;
}


export const canRemoveCustomerPermission = (assignable, user) => {
    return user.roleName.every(role => assignable.includes(role));
}


export const disabledPermission = (permissionLevel, me) => {
    //Får aldrig ta bort admin
    if (permissionLevel === 'Admin') {
        return true;
    }
    //får ta bort instanceadmin om jag är admin
    if (!me.permissionLevels.includes('Admin') && permissionLevel === 'InstanceAdmin') {
        return true;
    }
    return false;
}

export const isUserAdmin = (user) => {
    if (user.roleName.includes('Admin') || user.roleName.includes('InstanceAdmin')) {
        return true;
    }
    return false;
}

export const isAdmin = (user) => {
    return user.permissionLevels.includes('Admin'); 
}

export const canEditLicenseKeys = (me) => {
    if (me.permissionLevels.includes('Admin') || me.permissionLevels.includes('LicenseAdmin')) {
        return true;
    }
    return false;
}
