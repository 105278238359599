import React, { Component } from 'react';
import { Input } from 'reactstrap';

export class Filter extends Component {

    updateData = (e) => {
        let value = e.target.value;
        this.props.filterSearch(this.props.name, value)
    }

    render() {
        const props = this.props;
        return (
            <Input type="select" value={props.selected || 'all'} onChange={this.updateData} disabled={props.disabled}>
                <option value='all'>{props.defaultValue}</option>
                {props.globalValue && <option value='global'>{props.globalValue}</option>}
                {props.data && props.data.sort(comparer).map((data, i) => (<option key={i} value={data.value}>{data.text}</option>))}
            </Input>
        )
    }
}

const comparer = (a, b) => {
    if (a.text < b.text) {
        return -1;
    }

    if (a.text > b.text) {
        return 1;
    }

    return 0;
}

export default Filter;
