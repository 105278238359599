import React, { useState, useEffect } from 'react';
import { Button, Input, FormGroup, Card, CardHeader, Collapse, CardBody, CardFooter } from 'reactstrap';
import { useSelector, useDispatch } from 'react-redux';
import Loading from '../misc/Loading';
import { getUser, clearGetUser } from '../store/UserPickerAction';
import { getCustomerPermissionGroups } from '../store/GroupPermissionsAction';
import debounce from 'lodash/debounce';
import StatusCode from '../util/StatusCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConsentModal from './ConsentModal';


const AddUser = (props) => {
    const [inputSearchString, setInputSearchString] = useState('');
    const [searchString, setSearchString] = useState('');
    const [openConsent, setOpenConsent] = useState(false);
    const [consentText, setConsentText] = useState(false);
    const [selectedItems, setSelectedItems] = useState([]);
    const [customerTenant, setCustomerTenant] = useState(false);
    const [open, setOpen] = useState(true);
    const [tab, setTab] = useState('myTenant');
    const [didMount, setDidMount] = useState(false);

    const dispatch = useDispatch();

    const user = useSelector(state => state.userpicker.user);
    const customerPermissionGroups = useSelector(state => state.groups.customerPermissionGroups);

    useEffect(() => {
        if (!didMount) {
            clearGetUser()(dispatch);
            setDidMount(true);
            return;
        }
        if (tab === 'group') {
            searchGroup();
        } else {
            searchUser();
        }

    }, [tab, searchString])

    const searchUser = () => {
        if (searchString.length >= 2) {
            getUser({ customerId: props.customer.customerId, customerTenant: customerTenant, searchString: searchString })(dispatch);
            props.showSavingState && props.clearState();
        } else {
            props.showSavingState && props.clearState();
            clearGetUser()(dispatch);
        }
    }

    const searchGroup = () => {
        props.showSavingState && props.clearState();
        getCustomerPermissionGroups(props.customer.customerId)(dispatch)
    }

    const renderGroups = () => {
        var result = customerPermissionGroups;
        var cards = {
            code: result.code,
            data: result.data ? result.data.map(g => ({
                id: g.id,
                title: g.displayName,
                subtitle: g.customerName === null ? 'Global grupp' : g.customerName,
                text: `Medlemmar: ${g.members.length}`,
                object: { ...g, objectType: 'group' }
            })) : []
        };
        return renderCards(cards);
    }

    const renderUsers = () => {
        var result = user;
        var cards = {
            code: result.code,
            data: result.data ? result.data.map(u => ({
                id: u.externalId,
                title: u.displayName,
                subtitle: u.title,
                text: u.email,
                object: { ...u, id: u.externalId, objectType: 'user' }
            })) : [],
            error: result.error ? result.error : null
        };
        return renderCards(cards);
    }

    const renderCards = (result) => {
        if (result.code === StatusCode.NONE) {
            return <p>Sök för att visa användare</p>
        }
        if (result.code === StatusCode.PENDING) {
            return <Loading class='spinnerSmall' />
        }
        if (result.code === StatusCode.ERROR) {
            if (result.error.response.status === 422) {
                if (result.error.response.data.reason === "NoSuchTenant") {
                    return <p>{result.error.response.data.description}</p>
                } else if (consentText) {
                    return <div><p>Vi saknar behörighet att söka i kundens miljö.</p>
                        <p>Om du nyligen godkänt behörigheterna kan det ta en liten stund innan det fungerar.</p>
                        <Button className="btn-primary" onClick={() => setOpenConsent(true)}>Klicka här för att godkänna behörighet</Button></div>

                } else {
                    return <div><p>Vi saknar behörighet att söka i kundens miljö.</p> <Button className="btn-primary" onClick={() => setOpenConsent(true)}>Klicka här för att godkänna behörighet</Button></div>
                }
            } else {
                return <p>Kunde inte hämta data</p>
            }
        }
        if (result.code === StatusCode.COMPLETE && result.data.length === 0) {
            return <p>Inga resultat</p>
        }
        if (result.code === StatusCode.COMPLETE) {
            return (
                <div className="user_card_box">
                    {result.data.map(u => {
                        let active = false
                        selectedItems.length > 0 && selectedItems.forEach(ul => {
                            if (ul.id === u.id) {
                                active = true
                            }
                        })
                        return (
                            <div className={"user_card " + (active ? 'active' : '')} key={u.id} onClick={() => updateList(u.object)}>
                                <div className="title">{u.title}</div>
                                <div className="subtitle">{u.subtitle}</div>
                                <p>{u.text}</p>
                            </div>)
                    })}
                </div>
            )
        }
    }

    const updateList = (item) => {
        props.showSavingState && props.clearState();
        const existing = selectedItems.some(s => s.id === item.id);

        let updates = undefined;

        if (existing) {
            updates = selectedItems.filter(s => s.id !== item.id)
        } else {
            updates = [...selectedItems, item];
        }
        setSelectedItems(updates);
        props.selectedItems(updates);
    }

    const toggleConsentModal = () => {
        setOpenConsent(false);
        setConsentText(true);
        searchUser();
    }

    const renderConsentModal = () => {
        return (
            <ConsentModal
                isOpen={openConsent}
                toggleModal={toggleConsentModal}
                disabled={props.disabled}
                data={user.error.response.data}
                customer={props.customer}
            />);
    }

    const debouncedSearch = React.useRef(
        debounce(async (value) => {
            setSearchString(value)
        }, 300)
    ).current;

    const searchValue = (value) => {
        setInputSearchString(value);
        debouncedSearch(value);
    }

    return (
        <Card>
            <CardHeader tag="h5">
                <Button outline color="secondary" style={{ width: '37px', float: 'right' }} onClick={() => setOpen(!open)}>{open ? '-' : '+'}</Button>
                Användare{props.selectGroups ? ' / Grupper' : null}
            </CardHeader>
            <Collapse isOpen={open}>
                <CardBody>
                    <div className="search-container">
                        <div className="tabs">
                            <button className={"tab " + (tab === 'myTenant' ? 'active' : '')} onClick={(e) => { setTab(e.target.value); setCustomerTenant(false) }} value='myTenant' name="tab">Min miljö</button>
                            <button className={"tab " + (tab === 'customerTenant' ? 'active' : '')} onClick={(e) => { setTab(e.target.value); setCustomerTenant(true) }} value='customerTenant' name="tab">{props.customer.title}s miljö</button>
                            {props.selectGroups && <button className={"tab " + (tab === 'group' ? 'active' : '')} onClick={(e) => setTab(e.target.value)} value='group' name="tab">Grupper</button>}
                        </div>
                        <div className="content">
                            {(tab === 'myTenant' || tab === 'customerTenant') &&
                                <div className="inner">
                                    <div className="search">
                                        <FormGroup>
                                            <Input type="text" placeholder="Sök användare" name="inputSearchString" autoComplete="off" value={inputSearchString || ''} onChange={(e) => searchValue(e.target.value)} />
                                            <p>Ange minst 2 tecken</p>
                                        </FormGroup>
                                    </div>
                                </div>}
                            <div className="search-result">
                                {tab === 'group' && renderGroups()}
                                {tab !== 'group' && renderUsers()}
                            </div>
                        </div>
                    </div>
                </CardBody>
                <CardFooter>
                    <div className={"list-group-members " + (selectedItems.length > 9 ? 'column-2' : '')} >
                        {selectedItems.map(s => {
                            return (
                                <div className="group-member" key={s.id}>
                                    <p><b>{s.displayName}</b>{s.email ? `, ${s.email}` : null} {s.title ? <i>, {s.title}</i> : null}</p>
                                    <span onClick={() => updateList(s)}><FontAwesomeIcon className="icon" icon="trash" /></span>
                                </div>)
                        })}
                    </div>
                </CardFooter>
            </Collapse>
            {openConsent && renderConsentModal()}
        </Card>
    );
}


export default AddUser;
