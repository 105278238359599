import React, { useState, useEffect } from 'react';
import { Container, Col, Row, Button } from 'reactstrap';
import { useDispatch, useSelector } from 'react-redux';
import * as PermissionLevel from './util/PermissionLevelUtil';
import { getAuditLog, getAuditLogEventTypes } from './store/AuditLogAction';
import { GetInstance } from './store/InstanceAction';
import Filter from './modules/Filter';
import * as AuditLogEvent from './util/AuditLogEvent';
import debounce from 'lodash/debounce';
import Pagination from './misc/Pagination';
import Search from './misc/Search';
import * as Helper from './util/Helper';
import * as AuditLogSourceTypeUtil from './util/AuditLogSourceTypeUtil';
import { Link, useParams } from 'react-router-dom';
import StatusCode from './util/StatusCode';
import TableHorizontal from './misc/TableHorizontal';
import store from './store/store';
import ModalComponent from './misc/ModalComponent';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AuditLog = () => {
    const { customerId } = useParams();
    const dispatch = useDispatch();

    const [search, setSearch] = useState("");
    const [guiFilter, setFilter] = useState({});
    const [open, setOpen] = useState(false);
    const [additionalInformation, setAdditionalInformation] = useState(undefined);

    const me = useSelector(state => state.auth.userAuthorized);
    const auditLog = useSelector(state => state.auditLog.auditLog);
    const customer = useSelector(state => state.instance.instance);
    const eventTypes = useSelector(state => state.auditLog.eventTypes);
    const filter = useSelector(state => state.auditLog.filter);

    useEffect(() => {
        Promise.all([
            GetInstance(customerId)(dispatch),
            getAuditLogEventTypes()(dispatch),
            getAuditLog(customerId, {}, true)(dispatch),
        ]);
    }, [dispatch]);

    var interval;

    useEffect(() => {
        interval = setInterval(() => {
            const storeFilter = store.getState();
            getAuditLog(customerId, { ...storeFilter.auditLog.filter }, false)(dispatch);
        }, 60000);
        return () => { clearInterval(interval) }
    }, [dispatch]);

    const debouncedSearch = React.useRef(
        debounce(async (search) => {
            getAuditLog(customerId, { ...filter, search: search, page: 1 })(dispatch);
        }, 300)
    ).current;

    const onSearch = (value) => {
        setSearch(value);
        debouncedSearch(value);
    }

    const onChangePage = (p) => {
        getAuditLog(customerId, { ...filter, page: p })(dispatch);
    }

    const filterSearch = (name, value) => {
        if (value === 'all') {
            value = undefined;
        }

        setFilter({ ...guiFilter, [name]: value });
        getAuditLog(customerId, { ...filter, [name]: value, page: 1 })(dispatch);
    }

    const renderCatagories = () => {
        if (eventTypes.status === StatusCode.ERROR) {
            return 'Kunde inte hämta beskrivningar.'
        } else if (eventTypes.data) {
            return (<Filter
                data={eventTypes.data.map(e => ({ value: e.eventId, text: AuditLogEvent.translateEvent(e, 'sv-SE') }))}
                filterSearch={(name, value) => filterSearch(name, value)}
                name='eventId'
                selected={guiFilter.eventId}
                defaultValue="Visa alla beskrivningar"
                disabled={auditLog.status === StatusCode.PENDING}
            />);
        } else {
            return 'Laddar...';
        }
    }

    const sourceName = (log, eventId) => {
        const instanceUrl = customer.data ? customer.data.url : null;

        switch (log.type) {
            case 1: {
                if (instanceUrl && eventId === 10003) {
                    return <a href={`${instanceUrl}/module/${log.id}/status`} target="_blank">{log.name}</a>
                } else if (instanceUrl) {
                    return <a href={`${instanceUrl}/module/${log.id}/configuration`} target="_blank">{log.name}</a>
                } else {
                    return log.name;
                }
            }
            case 3: {
                return <Link to={`/license/${customerId}`} target="_blank">{log.name}</Link>
            }
            case 4: {
                return <Link to={`/permissiongroups/`} target="_blank">{log.name}</Link>
            }
            case 5: {
                return <Link to={`/permissions/${customerId}`} target="_blank">{log.name}</Link>
            }
            case 7: {
                return <a href={`${instanceUrl}/user/${log.id}`} target="_blank">{log.name}</a>
            }
            default: return log.name
        }
    }

    const renderSearch = () => {
        return (<>
            <Row>
                <Search disabled={auditLog.status === StatusCode.PENDING} value={search} onSearch={(search) => onSearch(search)} placeholder="Sök granskningslogg" name="search" />
                <Col sm="6">
                    {renderCatagories()}
                </Col>
            </Row>
        </>)
    }

    const renderBody = () => {
        let auditLogList = [];
        if (auditLog.status === StatusCode.COMPLETE) {
            auditLogList = auditLog.data.map(a => ({
                id: a.id,
                data: [
                    { value: AuditLogEvent.translateEvent(a.event, 'sv-SE') },
                    { value: sourceName(a.source, a.event.eventId) },
                    { value: AuditLogSourceTypeUtil.sourceTypeFromId(a.source.type) || '-' },
                    { value: `${a.user.displayName || '-'}, ${a.user.email || '-'} ` },
                    { value: Helper.formatDateTime(a.timestamp) || '' },
                    { value: a.additionalInformation && <Button className="icon-btn right" title="Visa avancerad information" onClick={() => (setOpen(true), setAdditionalInformation(a.additionalInformation)) }><FontAwesomeIcon icon="ellipsis-vertical" /></Button> }
                ]
            }))
        }

        return (<>
            <TableHorizontal
                header={[{ value: 'Beskrivning' }, { value: 'Titel' }, { value: 'Typ' }, { value: 'Användare' }, { value: 'Tidpunkt' }, { value: 'Avancerad information' }]}
                info={auditLogList}
                error={auditLog.status === StatusCode.ERROR}
                searching={auditLog.status === StatusCode.PENDING}
            />
            {auditLog.status === StatusCode.COMPLETE &&
                <Pagination currentPage={auditLog.pagination.currentPage} totalPages={auditLog.pagination.totalPages} onChangePage={onChangePage} />
            }
        </>)
    }

    const renderContent = () => {
        if (PermissionLevel.canEditCustomer(me, customerId)) {
            return (<>
                {renderSearch()}
                {renderBody()}

                {open && <ModalComponent
                    isOpen={open}
                    toggleModal={() => (setOpen(false), setAdditionalInformation(undefined))}
                    header="Avancerad information"
                    size="xl"
                    cancel="Stäng"
                >
                    <div>
                        <pre>{Helper.formatText(additionalInformation)}</pre>
                    </div>
                </ModalComponent>}
            </>)
        }
        return 'Kan inte visa granskningsloggar';
    }

    const renderTitle = () => {
        if (customer.status === StatusCode.ERROR) {
            return 'Kan inte hämta titel';
        } else if (customer.data) {
            return customer.data.title;
        } else {
            return 'Laddar...';
        }
    }

    return (
        <div className="page auditlog">
            <div>
                <Container>
                    <h1>Granskningslogg</h1>
                    <p className="title">{renderTitle()}</p>
                </Container>
            </div>
            <Container>
                {renderContent()}
            </Container >
        </div >
    );
};

export default AuditLog;
