import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';


const UnAuthorized = () => (
    <div className="login">
        <Container>
            <h2>Du saknar behörighet för att se den här sidan</h2>
            <div className="btn-box">
                <Link to="/" className="btn">Till startsidan</Link>
            </div>
        </Container>
    </div>
);

export default UnAuthorized;
