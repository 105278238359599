import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_PERMISSION_GROUPS = 'GET_PERMISSION_GROUPS';
const UPDATE_GROUP = 'UPDATE_GROUP';
const DELETE_GROUP = 'DELETE_GROUP';
const GET_CUSTOMER_PERMISSION_GROUPS = 'GET_CUSTOMER_PERMISSION_GROUPS';



export const getPermissionGroups = (customerId, filterOnCustomer) => dispatch => {
    dispatch({
        type: GET_PERMISSION_GROUPS,
        permissionGroups: {
            status: StatusCode.PENDING
        }
    })

    const url = `api/v1/permissiongroup`;
    return HttpClient.get(url, { customerId, filterOnCustomer })
        .then(res => {
            dispatch({
                type: GET_PERMISSION_GROUPS,
                permissionGroups: {
                    status: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_PERMISSION_GROUPS,
                permissionGroups: {
                    status: StatusCode.ERROR,
                    error: err
                }
            })
        })
};

export const getCustomerPermissionGroups = (customerId) => dispatch => {
    dispatch({
        type: GET_CUSTOMER_PERMISSION_GROUPS,
        customerPermissionGroups: { code: StatusCode.PENDING }
    })
    const url = `api/v1/permissiongroup/${customerId}`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_CUSTOMER_PERMISSION_GROUPS,
                customerPermissionGroups: { code: StatusCode.COMPLETE, data: res.data }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_CUSTOMER_PERMISSION_GROUPS,
                customerPermissionGroups: { code: StatusCode.ERROR, error: err }
            })
        })
};


export const updateGroup = (group) => dispatch => {
    dispatch({
        type: UPDATE_GROUP, update: { status: StatusCode.PENDING }
    })
    const url = `api/v1/permissiongroup/`;
    return HttpClient.put(url, group)
        .then(res => {
            dispatch({
                type: UPDATE_GROUP, update: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_GROUP, update: { status: StatusCode.ERROR }
            })
        })
}

export const removeGroup = (groupId) => dispatch => {
    dispatch({
        type: DELETE_GROUP, deleteGroup: { status: StatusCode.PENDING }
    })
    const url = `api/v1/permissiongroup/${groupId}`;
    return HttpClient.delete(url)
        .then(() => {
            dispatch({
                type: DELETE_GROUP, deleteGroup: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: DELETE_GROUP, deleteGroup: { status: StatusCode.ERROR }
            })
        })
}

export const clearRemoveGroup = () => dispatch => {
    dispatch({ type: DELETE_GROUP, deleteGroup: { status: StatusCode.NONE } })
};

export const clearUpdateGroup = () => dispatch => {
    dispatch({ type: UPDATE_GROUP, update: { status: StatusCode.NONE } })
};


const initialState = {
    permissionGroups: { status: StatusCode.NONE },
    customerPermissionGroups: { code: StatusCode.NONE },
    deleteGroup: { status: StatusCode.NONE },
    update: { status: StatusCode.NONE }
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_PERMISSION_GROUPS:
            return {
                ...state,
                permissionGroups: action.permissionGroups,
            }
        case UPDATE_GROUP:
            return {
                ...state,
                update: action.update,
            }
        case DELETE_GROUP:
            return {
                ...state,
                deleteGroup: action.deleteGroup,
            }
        case GET_CUSTOMER_PERMISSION_GROUPS:
            return {
                ...state,
                customerPermissionGroups: action.customerPermissionGroups,
            }
        default:
            return state;
    }
}