import HttpClient from '../services/HttpClient';

import GoogleApi from '../services/GoogleApi';
import MsalApi from '../services/MsalApi';

const UPDATE_AUTH_STATUS = 'UPDATE_AUTH_STATUS';
export const RESET_AUTH_STATUS = 'RESET_AUTH_STATUS';

const updateGoogleAuthStatus = async (dispatch, token) => {
    if (!token) {
        return dispatch({
            type: UPDATE_AUTH_STATUS,
            needLogin: true
        });
    }

    localStorage.setItem('AuthAuthority', 'google');

    const authorized = await isUserAuthorized(token);
    dispatch({
        type: UPDATE_AUTH_STATUS,
        needLogin: false,
        userAuthorized: authorized,
        authority: 'google'
    });
    return token;
}

export const silentAuth = () => async dispatch => {
    const type = localStorage.getItem('AuthAuthority');
    switch (type) {
        case 'microsoft':
            try {
                const tokenResponse = await MsalApi.idTokenSilent();
                var token = tokenResponse.idToken.rawIdToken;
                const authorized = await isUserAuthorized(token);
                dispatch({
                    type: UPDATE_AUTH_STATUS,
                    userAuthorized: authorized,
                    needLogin: false,
                    authority: 'microsoft'
                });
                return token;
            } catch (err) {
                console.error(err)
                dispatch({
                    type: UPDATE_AUTH_STATUS,
                    needLogin: true
                });
                return;
            }
        case 'google':
            try {
                const token = await GoogleApi.token();
                return await updateGoogleAuthStatus(dispatch, token);
            }
            catch (err) {
                console.error(err)
                dispatch({
                    type: UPDATE_AUTH_STATUS,
                    needLogin: true
                });
                return;
            }
        default:
            dispatch({
                type: UPDATE_AUTH_STATUS,
                needLogin: true
            });
    }
};

const isUserAuthorized = async (token) => {
    const url = '/api/v1/user/me?includemasterpermissions=true&includeallcustomerpermissions=true';
    let authorized = false;
    if (token) {
        try {
            var res = await HttpClient.get(url);
            authorized = res.data
        } catch (e) {
            console.error(e);
        }
    }

    return authorized;
}

export const googleAuth = (token) => async dispatch => {
    window.localStorage.setItem('AuthAuthority', 'google');
    await updateGoogleAuthStatus(dispatch, token);
};

export const microsoftAuth = () => async dispatch => {
    window.localStorage.setItem('AuthAuthority', 'microsoft');

    const tokenResponse = await MsalApi.idTokenPopup();
    var token = tokenResponse.idToken.rawIdToken;
    const authorized = await isUserAuthorized(token);

    await dispatch({
        type: UPDATE_AUTH_STATUS,
        userAuthorized: authorized,
        needLogin: false,
        authority: 'microsoft'
    });
};

export const signOut = () => async dispatch => {
    const authAuthority = localStorage.getItem('AuthAuthority');
    window.localStorage.removeItem('AuthAuthority');
    switch (authAuthority) {
        case 'google':
            GoogleApi.logout();
            break;
        case 'microsoft':
            await MsalApi.logout();
            break;
        default:
    }

    return dispatch({
        type: RESET_AUTH_STATUS
    });
};

const initialState = {
    needLogin: undefined, 
    authority: undefined,
    userAuthorized: undefined,
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case UPDATE_AUTH_STATUS:
            return {
                ...state,
                needLogin: action.needLogin,
                authority: action.authority,
                userAuthorized: action.userAuthorized
            };
        case RESET_AUTH_STATUS:
            return {
                ...state,
                needLogin: true,
                authority: undefined,
                userAuthorized: undefined,
            };
        default:
            return state;
    }

};
