import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';


const ModalComponent = (props) => {
    return (
        <Modal size={props.size} isOpen={props.isOpen} toggle={props.toggleModal}>
            <ModalHeader toggle={props.toggleModal}>
                {props.header}
            </ModalHeader>
            <ModalBody>
                {props.children}
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggleModal}>{props.cancel}</Button>
                {props.update && <Button disabled={props.disabled} onClick={props.update}>{props.confirm}</Button>}
            </ModalFooter>
        </Modal>
    )
}

export default ModalComponent;
