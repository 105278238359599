import React, { useState, useEffect } from 'react';
import { Button, Container } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConsumerCreateUpdate from './modules/ConsumerCreateUpdate';
import { getConsumers, updateConsumer, clearUpdateConsumer, deleteConsumer } from './store/ConsumerAction';
import { useSelector, useDispatch } from 'react-redux';
import StatusCode from './util/StatusCode';
import * as PermissionLevel from './util/PermissionLevelUtil';
import TableHorizontal from './misc/TableHorizontal';
import UserModal from './modules/Modal';


const defaultConsumer = { name: undefined, clientId: undefined };

const Consumers = () => {
    const [consumer, setConsumer] = useState(defaultConsumer);
    const [crud, setCrud] = useState(undefined);
    const consumers = useSelector(state => state.consumer.consumers);
    const update_consumer = useSelector(state => state.consumer.update_consumer);
    const me = useSelector(state => state.auth.userAuthorized);
    const dispatch = useDispatch();

    useEffect(() => {
        getConsumers()(dispatch);
    }, [dispatch]);

    const saveConsumer = (consumer, remove) => {
        if (remove) {
            deleteConsumer(consumer.id)(dispatch);
        } else {
            updateConsumer(consumer)(dispatch);
        }
    }

    const toggleModal = () => {
        if (update_consumer.status !== StatusCode.NONE) {
            clearUpdateConsumer()(dispatch);
            getConsumers()(dispatch);
        }
        setCrud(undefined);
        setConsumer(defaultConsumer);
    }

    const renderTable = () => {
        let list = [];

        if (consumers.status === StatusCode.COMPLETE) {
            list = consumers.data.map(c => ({
                id: c.id,
                data: [
                    { value: c.name },
                    { value: c.clientId },
                    { value: <><FontAwesomeIcon icon="pen" title="Redigera konsument" onClick={() => (setConsumer(c), setCrud('UPDATE'))} /></>, className: 'edit-user' },
                    { value: <><FontAwesomeIcon icon="trash" title="Radera konsument" onClick={() => (setConsumer(c), setCrud('REMOVE'))} /></>, className: 'edit-user' },
                ]
            }));
        }

        return (<>
            <TableHorizontal
                header={[{ value: 'Namn' }, { value: 'Klient-id' }, { value: 'Redigera', colSpan: 2 }, { value: '' } ]}
                info={list}
                error={consumers.status === StatusCode.ERROR}
                searching={consumers.status === StatusCode.PENDING}
            />
            <Button className="btn-primary add_user-btn" onClick={() => setCrud('CREATE')}>Skapa ny API konsument</Button>
        </>);
    }

    const showModal = () => (
        <ConsumerCreateUpdate
            isOpen={crud === 'CREATE' || crud === 'UPDATE'}
            consumer={consumer}
            toggle={() => toggleModal()}
            update={(consumer) => saveConsumer(consumer, false)}
            savingState={update_consumer.status}
            crud={crud}
            error={update_consumer.status === StatusCode.ERROR ? update_consumer.data : null}
        />
    );

    const deleteConsumerModal = () => (
        <UserModal
            header='Radera API konsument'
            body={`Vill du radera API konsumenten ${consumer.name}?`}
            isOpen={crud === 'REMOVE'}
            toggleModal={() => toggleModal()}
            update={() => saveConsumer(consumer, true)}
            savingState={update_consumer}
            cancel="Nej"
            confirm="Ja"
        />
    );

    return (
        <div className="page consumers">
            <div>
                <Container>
                    <h1>API Konsumenter</h1>
                </Container>
            </div>
            <Container>
                {PermissionLevel.isAdmin(me) ? <>
                    {renderTable()}
                    {(crud === 'CREATE' || crud === 'UPDATE') && showModal()}
                    {crud === 'REMOVE' && deleteConsumerModal()}
                </> : 'Du saknar behörighet för att administrera API konsumenter'}
            </Container>
        </div>
    );
}

export default Consumers;
