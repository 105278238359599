import React from 'react';
import Loading from '../misc/Loading';
import StatusCode from '../util/StatusCode';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const SavingState = ({ status }) => {
    if (status === StatusCode.PENDING) {
        return (<Loading class="spinnerSmall" />)
    }
    if (status === StatusCode.COMPLETE) {
        return <p><FontAwesomeIcon icon="check-circle" className="saving-icon ok" />Sparat</p>
    }
    if (status === StatusCode.ERROR) {
        return (<p><FontAwesomeIcon icon="xmark-circle" className="saving-icon error" /> Något gick fel! Kunde inte spara</p>);
    }
    return null;
}

export default SavingState;
