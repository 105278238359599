import admentum from './admentum.png';
import alvis from './alvis.png';
import asm from './asm.png';
import binogi from './binogi.png';
import classroom from './classroom.png';
import dugga from './dugga.png';
import gleerups from './gleerups.png';
import haldor from './haldor.png';
import ilt from './ilt.png';
import infomentor from './infomentor.png';
import ist from './ist.png';
import lexplore from './lexplore.png';
import ne from './ne.png';
import quiculum from './quiculum.png';
import schoolsoft from './schoolsoft_logo_dark.png';
import skolon from './Skolon.png';
import skolplatsen from './skolplatsen.png';
import skolverket from './skolverket.png';
import teams from './teams.png';
import tieto from './tieto.png';

const logos = [
    {
        name: 'Admentum',
        img: admentum
    },
    {
        name: 'Alvis',
        img: alvis
    },
    {
        name: 'Apple School Manager',
        img: asm
    },
    {
        name: 'Binogi',
        img: binogi
    },
    {
        name: 'Classroom',
        img: classroom
    },
    {
        name: 'Dugga',
        img: dugga
    },
    {
        name: 'Gleerups',
        img: gleerups
    },
    {
        name: 'Haldor',
        img: haldor
    },
    {
        name: 'Inläsningstjänst',
        img: ilt
    },
    {
        name: 'Infomentor',
        img: infomentor
    },
    {
        name: 'Ist',
        img: ist
    },
    {
        name: 'Lexplore',
        img: lexplore
    },
    {
        name: 'NE',
        img: ne
    },
    {
        name: 'Quiculum',
        img: quiculum
    },
    {
        name: 'SchoolSoft',
        img: schoolsoft
    },
    {
        name: 'Skolon',
        img: skolon
    },
    {
        name: 'Skolplatsen',
        img: skolplatsen
    },
    {
        name: 'Skolverket',
        img: skolverket
    },
    {
        name: 'Teams',
        img: teams
    },
    {
        name: 'Tieto',
        img: tieto
    },
]

export default logos;