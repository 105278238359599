import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const LIST_INSTANCES = 'LIST_INSTANCES';
const GET_INSTANCE = 'GET_INSTANCE';
const GET_STATUS = 'GET_STATUS';
const GET_MODULES = 'GET_MODULES';

export const ListInstances = (canEdit) => dispatch => {
    const url = 'api/v1/instance';
    return HttpClient.get(url, {canEdit})
        .then(res => {
            dispatch({
                type: LIST_INSTANCES,
                instances: res.data
            })
            return res.data;
        })
        .catch(err => {
            dispatch({
                type: LIST_INSTANCES,
                error: true
            })
        })
};

export const GetInstance = (instanceId, dispatchPending = true) => dispatch => {
    if (dispatchPending) {
        dispatch({ type: GET_INSTANCE, instance: { status: StatusCode.PENDING } })
    }
    
    return HttpClient.get(`api/v1/instance/${instanceId}`).then(resp => dispatch({
        type: GET_INSTANCE,
        instance: {
            status: StatusCode.COMPLETE,
            data: resp.data,
        },
    })).catch(error => dispatch({
        type: GET_INSTANCE,
        instance: {
            status: StatusCode.COMPLETE,
            error,
        },
    }));
}

export const GetStatus = (instanceId) => dispatch => {
    const url = `api/v1/instance/${instanceId}/status`;
    HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_STATUS,
                instanceId: instanceId,
                instanceStatus: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_STATUS,
                instanceId: instanceId,
                instanceStatus: 'error'
            })
        })
};

export const GetModules = () => dispatch => {
    dispatch({ type: GET_MODULES, modules: { status: StatusCode.PENDING } });

    HttpClient.get("api/v1/instance/modules")
        .then(res => {
            dispatch({
                type: GET_MODULES,
                modules: {
                    status: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MODULES,
                modules: {
                    status: StatusCode.ERROR,
                    error: err
                }
            })
        })
};

export const setServiceAccount = (customerId, serviceAccount) => () => {
    const url = `api/v1/instance/${customerId}/serviceAccount`;
    return HttpClient.patch(url, { serviceAccount: serviceAccount })
}

const initialState = {
    instances: undefined,
    error: false,
    instance: {
        status: StatusCode.NONE,
    },
    instanceStatus: undefined,
    instanceStatuses: {},
    modules: { status: StatusCode.NONE },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case LIST_INSTANCES:
            return {
                ...state,
                instances: action.instances,
                error: action.error
            }
        case GET_INSTANCE:
            return {
                ...state,
                instance: action.instance,
            };
        case GET_STATUS:
            return {
                ...state,
                instanceStatuses: {
                    ...state.instanceStatuses,
                    [action.instanceId]: action.instanceStatus
                }
            };
        case GET_MODULES:
            return {
                ...state,
                modules: action.modules,
            };
        default:
            return state;
    }
}