import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_CONSUMERS = 'GET_CONSUMERS';
const UPDATE_CONSUMER = 'UPDATE_CONSUMER';

export const getConsumers = () => dispatch => {
    dispatch({ type: GET_CONSUMERS, consumers: { status: StatusCode.PENDING } });

    const url = `api/v1/consumer`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_CONSUMERS,
                consumers: { status: StatusCode.COMPLETE, data: res.data }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_CONSUMERS,
                consumers: { status: StatusCode.ERROR, data: err }
            })
        })
}

export const updateConsumer = (consumer) => dispatch => {
    dispatch({ type: UPDATE_CONSUMER, update_consumer: { status: StatusCode.PENDING } });
    
    const url = `api/v1/consumer`;
    return HttpClient.post(url, consumer)
        .then(res => {
            dispatch({
                type: UPDATE_CONSUMER,
                update_consumer: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_CONSUMER,
                update_consumer: { status: StatusCode.ERROR, data: err }
            })
        })
}

export const clearUpdateConsumer = () => dispatch => {
    dispatch({ type: UPDATE_CONSUMER, update_consumer: { status: StatusCode.NONE } });
}

export const deleteConsumer = (consumerId) => dispatch => {
    dispatch({ type: UPDATE_CONSUMER, update_consumer: { status: StatusCode.PENDING } });

    const url = `api/v1/consumer/${consumerId}`;
    return HttpClient.delete(url)
        .then(res => {
            dispatch({
                type: UPDATE_CONSUMER,
                update_consumer: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_CONSUMER,
                update_consumer: { status: StatusCode.ERROR, data: err }
            })
        })
}

const initialState = {
    consumers: { status: StatusCode.NONE },
    update_consumer: { status: StatusCode.NONE },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_CONSUMERS:
            return {
                ...state,
                consumers: action.consumers,
            };
        case UPDATE_CONSUMER:
            return {
                ...state,
                update_consumer: action.update_consumer,
            };
        default:
            return state;
    }
}