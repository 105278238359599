const sourceTypes = new Map<number, string>([
    [0, "Okänd"],
    [1, "Modul"],
    [2, "Apptoken"],
    [3, "Licens"],
    [4, "Grupp"],
    [5, "Användare"],
    [6, "Kund"],
    [7, "Skoladmin användare"],
]);

export const sourceTypeFromId = (id: number): string => sourceTypes.get(id) ?? id.toString();
