import React, { Component } from 'react';
import UserModal from './Modal';
import { Input, FormGroup, Label, FormFeedback, Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../util/StatusCode';
import { updateGroup, clearUpdateGroup } from '../store/GroupPermissionsAction';
import * as Helper from '../util/Helper';
import AddUser from './AddUser';
import SavingState from '../misc/SavingState';


const validate = (group) => {
    const validation = [];
    if (!group.displayName) {
        validation.push('displayName');
    }
    if (!group.id && !group.customerId) {
        validation.push('customerId')
    }
    return validation;
}

class EditGroup extends Component {
    state = {
        group: this.props.group,
        save: false,
        new: false,
        users: [],
    }

    componentDidMount() {
        this.props.clearUpdateGroup();
    }

    updateGroupState = (e) => {
        var prop = e.target.name;
        var value = e.target.value;

        if (value === "") {
            value = null;
        }
        this.setState({ group: { ...this.state.group, [prop]: value } })
    }

    updateGroup = async () => {
        this.setState({ save: true })
        const group = Helper.mergeWithUpdates(this.props.group, this.state.group);

        const isValid = validate(group).length === 0;
        if (isValid) {
            await this.props.updateGroup(group);
        }
    }

    closeModal = () => {
        if (this.props.updateStatus.status !== StatusCode.NONE) {
            this.props.update();
        }
        this.props.toggleModal();
    }

    updateMembers = () => {
        this.setState({ new: false })
        this.setState({ group: { ...this.state.group, members: [...this.state.group.members, ...this.state.users] } })
    }

    renderAddUser(group) {
        const customer = this.props.instances.find(i => i.customerId === group.customerId);

        return (
            <UserModal
                className="lg"
                header="Lägg till användare"
                body={<AddUser
                    customer={customer}
                    selectedItems={(users) => this.setState({ users: users })}
                    selectGroups={false}
                    showSavingState={false}
                />}
                isOpen={this.state.new}
                toggleModal={() => this.setState({ new: !this.state.new, users: [] })}
                update={this.updateMembers}
                disabled={this.props.disabled}
                cancel="Avbryt"
                confirm="Lägg till"
                savingState={this.props.updateStatus}
            />
        )
    }

    removeMember = (member) => {
        const updates = this.state.group.members.filter(m => m.id !== member.id)
        this.setState({ group: { ...this.state.group, members: updates } })
    }

    renderMembers(members) {
        if (!members.length) {
            return <p>Gruppen saknar användare</p>
        } else {
            return (
                <div className={"list-group-members " + (members.length > 9 ? 'column-2' : '')} >
                    {members.map((m, i) => (
                        <div className="group-member" key={i + m.id}>
                            <p><b>{m.displayName},</b> {m.email}{m.title ? <i>, {m.title}</i> : null}</p>
                            <span onClick={() => this.removeMember(m)}><FontAwesomeIcon className="icon" icon="trash" /></span>
                        </div>)
                    )}
                </div>
            )
        }
    }

    render() {
        const { instances, add } = this.props;
        const { save } = this.state;
        const group = Helper.mergeWithUpdates(this.props.group, this.state.group);
        const validation = validate(group);

        return (
            <Modal className="lg add-user-modal" isOpen={this.props.isOpen} toggle={this.closeModal}>
                <ModalHeader toggle={this.closeModal}>
                    {add ? 'Skapa grupp' : 'Uppdatera grupp'}
                </ModalHeader>
                <ModalBody>

                    <FormGroup>
                        <Label for="displayName">Namn</Label>
                        <Input type="text" name="displayName" id="displayName" placeholder="Namn" value={group.displayName || ''} disabled={this.props.disableInput} onChange={this.updateGroupState} invalid={save && validation.indexOf('displayName') !== -1} autoComplete="off" />
                        <FormFeedback>Gruppen måste ha ett namn</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label for="customerId">Kund</Label>
                        <Input type="select" name="customerId" disabled={this.props.group.id !== undefined} value={group.customerId || ''} onChange={this.updateGroupState} invalid={save && validation.indexOf('customerId') !== -1} >
                            <option disabled value=''>Välj kund</option>
                            {instances
                                .sort((a, b) => a.title.localeCompare(b.title))
                                .map(i =>
                                    <option key={i.customerId} value={i.customerId}>{i.title}</option>
                                )}
                        </Input>
                        <FormFeedback>Gruppen måste vara kopplad till en kund</FormFeedback>
                    </FormGroup>

                    <FormGroup>
                        <Label>Användare</Label>
                        {this.renderMembers(group.members)}
                    </FormGroup>

                    <Button className="btn-primary add-user" disabled={!group.customerId} onClick={() => this.setState({ new: !this.state.new })}>Lägg till användare</Button>
                    {this.state.new && this.renderAddUser(group)}

                </ModalBody>
                <ModalFooter>
                    <Button onClick={this.closeModal}>Avbryt</Button>
                    <Button className="btn-primary" disabled={this.props.disabled} onClick={this.updateGroup}>{add ? 'Skapa' : 'Uppdatera'}</Button>
                    <SavingState status={this.props.updateStatus.status} />
                </ModalFooter>
            </Modal>
        );
    }
}

const mapStateToProps = state => ({
    instances: state.instance.instances,
    updateStatus: state.groups.update,
});

export default connect(mapStateToProps, { updateGroup, clearUpdateGroup })(EditGroup);
