import React, { useEffect, useState } from 'react';
import UserModal from './Modal';
import { Input, Label, FormGroup } from 'reactstrap';
import { getCustomerPermissionsLevels } from '../store/CustomerPermissionsAction'
import { useParams } from 'react-router-dom';
import * as PermissionLevel from '../util/PermissionLevelUtil';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { createCustomerPermissionUpdate } from '../util/CustomerPermissionHelper';
import { useDispatch, useSelector } from 'react-redux';

const arrayEquals = (module, updates) => {
    if (module.length !== updates.length)
        return false;
    module.sort();
    updates.sort();

    for (var i = 0; i < module.length; i++) {
        if (module[i] !== updates[i])
            return false;
    }
    return true;
}

const EditCustomerUser = (props) => {
    const { customerId } = useParams();
    const dispatch = useDispatch();

    const [updatedPermissionLevels, setupdatePermissionLevels] = useState(props.user.roleName);

    const permissionLevels = useSelector(state => state.customerPermission.permissionLevels)
    const me = useSelector(state => state.auth.userAuthorized);

    useEffect(() => {
        getCustomerPermissionsLevels(customerId);
    }, [dispatch]);

    const updateUser = () => {
        const updates = createCustomerPermissionUpdate([props.user], updatedPermissionLevels);
        props.update(updates)
    }

    const onChangePermission = (e) => {
        const value = e.target.name;
        const updates = [...updatedPermissionLevels];

        var index = updates.indexOf(value);
        if (index > -1) {
            updates.splice(index, 1)
        } else {
            updates.push(value)
        }
        setupdatePermissionLevels(updates);
    }

    const disableBtn = arrayEquals(props.user.roleName, updatedPermissionLevels) || props.disabled;

    return (
        <UserModal
            header='Redigera behörigheter'
            body={<div>
                <p className="bold">{props.user.displayName}</p>
                {props.user.email ? <p>{props.user.email}</p> : ''}

                <p className="bold">Behörigheter</p>
                {permissionLevels && permissionLevels.map(pl =>
                    <FormGroup check key={pl}>
                        <Label check>
                            <Input type="checkbox" name={pl} defaultChecked={updatedPermissionLevels.indexOf(pl) !== -1} onChange={onChangePermission} disabled={PermissionLevel.disabledPermission(pl, me)} />
                            {pl}
                        </Label>
                    </FormGroup>
                )}
                {updatedPermissionLevels.length === 0 &&
                    <div className="information">
                        <FontAwesomeIcon className="icon" icon="exclamation-circle" /><p>Om du tar bort samtliga behörigheter, så kommer användaren försvinna från listan.</p>
                    </div>}

            </div>}
            isOpen={props.isOpen}
            toggleModal={() => props.toggleModal()}
            update={updateUser}
            disabled={disableBtn}
            savingState={props.savingState}
            cancel="Avbryt"
            confirm="Spara"
        />
    )
}

export default EditCustomerUser;
