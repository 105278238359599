import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, FormGroup, Label, Input, FormFeedback } from 'reactstrap';
import SavingState from '../misc/SavingState';


const validate = (consumer) => {
    const validation = [];
    if (!consumer.name || consumer.name.length < 0 || consumer.name.length > 64) {
        validation.push('name');
    }
    if (!consumer.clientId || consumer.clientId.length < 0 || consumer.clientId.length > 64) {
        validation.push('clientId');
    }
    return validation;
}

const ConsumerCreateUpdate = (props) => {
    const [consumer, setConsumer] = useState(props.consumer);
    const [save, setSave] = useState(false);
    let validation = validate(consumer);

    const updateConsumer = (e) => {
        setConsumer({ ...consumer, [e.target.name]: e.target.value })
    }

    const saveConsumer = () => {
        const valid = !validation.length;
        if (valid) {
            props.update(consumer);
        }
    }

    return (
        <Modal isOpen={props.isOpen} toggle={props.toggle}>
            <ModalHeader toggle={props.toggle}>
                {props.crud === 'CREATE' && 'Skapa API konsument'}
                {props.crud === 'UPDATE' && 'Uppdatera API konsument'}
            </ModalHeader>
            <ModalBody>
                <FormGroup>
                    <Label for="name">Namn</Label>
                    <Input type="text" name="name" placeholder="Namn" value={consumer.name || ''}
                        onChange={updateConsumer} disabled={props.crud === 'REMOVE'} autoComplete="off" invalid={save && validation.indexOf('name') !== -1} />
                    <FormFeedback>Ange konsumentens namn (max 64 tecken)</FormFeedback>
                </FormGroup>
                <FormGroup>
                    <Label for="clientId">Klient-id</Label>
                    <Input type="text" name="clientId" placeholder="KlientId" value={consumer.clientId || ''}
                        onChange={updateConsumer} disabled={props.crud === 'REMOVE'} autoComplete="off" invalid={save && validation.indexOf('clientId') !== -1} />
                    <FormFeedback>Ange konsumentens klientId (max 64 tecken)</FormFeedback>
                </FormGroup>
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggle}>Stäng</Button>
                <Button className="btn-primary" onClick={() => (saveConsumer(), setSave(true))}>
                    {props.crud === 'CREATE' && 'Skapa'}
                    {props.crud === 'UPDATE' && 'Uppdatera'}
                </Button>
                <SavingState status={props.savingState} />

                {props.error && <>
                    <p>Felmeddelanden</p>
                    <ul>
                        {props.error?.response?.data?.errors?.Name?.map((x, i) => <li key={i}>{x}</li>)}
                        {props.error?.response?.status === 409 ? <li>Det finns redan en API konsument med detta klient-id</li> : null}
                    </ul>
                </>}
            </ModalFooter>
        </Modal>
    )
}

export default ConsumerCreateUpdate;
