import React, { Component } from 'react';
import { Container, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import * as PermissionLevel from './util/PermissionLevelUtil';
import { getPermissionGroups, removeGroup, clearRemoveGroup } from './store/GroupPermissionsAction';
import Loading from './misc/Loading';
import { ListInstances } from './store/InstanceAction';
import EditGroup from './modules/EditGroup';
import Filter from './modules/Filter';
import StatusCode from './util/StatusCode';
import UserModal from './modules/Modal';


const initialGroup = {
    customerId: undefined,
    customerName: '',
    displayName: '',
    groupPermissions: [],
    id: undefined,
    members: []
}

class PermissionGroups extends Component {
    state = {
        edit: false,
        add: false,
        remove: false,
        group: initialGroup,
        customerId: undefined
    }

    componentDidMount() {
        this.update();
        this.props.ListInstances(true);
    }

    update = (customerId, filterOnCustomer) => {
        this.props.getPermissionGroups(customerId, filterOnCustomer);
        this.props.clearRemoveGroup();
    }

    filterSearch = (name, value) => {
        var filterOnCustomer = true;
        this.setState({
            [name]: value
        })
        if (value === 'global') {
            value = undefined;
        }
        if (value === 'all') {
            filterOnCustomer = false;
            value = undefined;
        }
        this.update(value, filterOnCustomer);
    }

    removeGroup = async () => {
        await this.props.removeGroup(this.state.group.id)
    }

    renderEditGroup() {
        return (
            <EditGroup
                group={this.state.group}
                add={this.state.add}
                isOpen={this.state.edit}
                toggleModal={() => this.setState({ edit: !this.state.edit, add: false, group: initialGroup })}
                disabled={false}
                update={() => this.update()}
            />
        )
    }

    toggleRemoveModal = () => {
        if (this.props.deleteGroup.status !== StatusCode.NONE) {
            this.update();
        }
        this.setState({ remove: false, group: initialGroup });
        this.props.clearRemoveGroup();
    }

    renderRemoveGroup() {
        return (
            <UserModal
                header='Radera grupp'
                body={`Vill du radera gruppen ${this.state.group.displayName}?`}
                isOpen={this.state.remove}
                toggleModal={this.toggleRemoveModal}
                update={this.removeGroup}
                disabled={this.props.disabled}
                savingState={this.props.deleteGroup}
                cancel="Nej"
                confirm="Ja"
            />
        )
    }

    renderGroupTable() {
        const { instances } = this.props;
        return (
            <Table className="user_permissions-table">
                <thead>
                    <tr className="filter_header">
                        <th>Namn</th>
                        <th>{instances ?
                            <Filter
                                data={this.props.instances.map(instance => ({ value: instance.customerId, text: instance.title }))}
                                filterSearch={(name, value) => this.filterSearch(name, value)}
                                name='customerId'
                                selected={this.state.customerId}
                                defaultValue='Visa alla kunder'
                                globalValue = 'Globala'
                                disabled={false}
                            /> : 'Kund'}</th>
                        <th>Antal användare</th>
                        <th colSpan="2">Redigera</th>
                    </tr>
                </thead>
                {this.renderTableBody()}
            </Table>
        )
    }

    renderTableBody() {
        const { groups } = this.props;
        const { edit, remove } = this.state;
        const canEditCustomer = (customerId) => PermissionLevel.canEditCustomer(this.props.me, customerId)

        const body = (info) => <tbody><tr><td>{info}</td></tr></tbody>

        if (groups.status === StatusCode.PENDING)
            return (body(<Loading />));
        if (groups.status === StatusCode.ERROR)
            return (body('Kunde inte hämta behörighetsgrupper'));
        if (groups.status === StatusCode.COMPLETE && groups.data.length === 0)
            return (body('Behörighetsgrupper saknas'));
        return (
            <tbody>
                {groups.status === StatusCode.COMPLETE && groups.data.map(g => 
                    <tr key={g.id}>
                        <td data-label="Namn">{g.displayName}</td>
                        <td data-label="Kund">{g.customerName ? g.customerName : "\u00a0"}</td>
                        <td data-label="Antal användare">{g.members.length}</td>
                        {canEditCustomer(g.customerId) && <td data-label="Redigera" title="Redigera grupp" className="edit-user" onClick={() => this.setState({ edit: !edit, group: g })}><FontAwesomeIcon icon="pen" /></td>}
                        {canEditCustomer(g.customerId) && <td data-label="Redigera" title="Radera grupp" className="edit-user" onClick={() => this.setState({ remove: !remove, group: g })}><FontAwesomeIcon icon="trash" /></td>}
                    </tr>
                )}
            </tbody>
        );
    }

    render() {
        const canEditGroupPermission = PermissionLevel.canEditGroupPermission(this.props.me)
        const { edit, remove } = this.state;

        return (
            <div className="page groups">
                <div>
                    <Container>
                        <h1>Behörighetsgrupper</h1>
                    </Container>
                </div>
                <Container>
                    {this.renderGroupTable()}
                    {canEditGroupPermission && <Button className="btn-primary add_user-btn" onClick={() => this.setState({ edit: !edit, add: true })}>Skapa ny grupp</Button>}

                    {edit && this.renderEditGroup()}
                    {remove && this.renderRemoveGroup()}
                </Container >
            </div >
        )
    }
}

const mapStateToProps = state => ({
    me: state.auth.userAuthorized,
    groups: state.groups.permissionGroups,
    instances: state.instance.instances,
    deleteGroup: state.groups.deleteGroup, 
});

export default connect(mapStateToProps, { getPermissionGroups, ListInstances, removeGroup, clearRemoveGroup })(PermissionGroups);
