import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_AUDITLOG = 'GET_AUDITLOG';
const SET_AUDITLOG_FILTER = 'SET_AUDITLOG_FILTER';
const LIST_EVENT_TYPES = 'LIST_EVENT_TYPES';

export const getAuditLog = (customerId, filter = {}, dispatchPending = true) => dispatch => {
    if (dispatchPending) {
        dispatch({ type: GET_AUDITLOG, auditLog: { status: StatusCode.PENDING } })
    }
    dispatch({ type: SET_AUDITLOG_FILTER, filter: filter })

    const url = `api/v1/auditlog/${customerId}`;
    return HttpClient.get(url, filter)
        .then(res => {
            dispatch({
                type: GET_AUDITLOG,
                auditLog: {
                    status: StatusCode.COMPLETE,
                    data: res.data.values,
                    pagination: { totalPages: res.data.totalPages, pageSize: res.data.pageSize, currentPage: res.data.currentPage },
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_AUDITLOG, auditLog: { status: StatusCode.ERROR, error: err }
            })
        })
}

export const getAuditLogEventTypes = (dispatchPending = true) => dispatch => {
    if (dispatchPending) {
        dispatch({
            type: LIST_EVENT_TYPES,
            eventTypes: { status: StatusCode.PENDING },
        });
    }

    return HttpClient.get('api/v1/auditLog/eventTypes').then(resp => dispatch({
        type: LIST_EVENT_TYPES,
        eventTypes: {
            status: StatusCode.COMPLETE,
            data: resp.data,
        },
    })).catch(error => dispatch({
        type: LIST_EVENT_TYPES,
        eventTypes: {
            status: StatusCode.ERROR,
            error,
        },
    }));
}

const initialState = {
    auditLog: { status: StatusCode.NONE },
    eventTypes: { status: StatusCode.NONE },
    filter: {},
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_AUDITLOG:
            return {
                ...state,
                auditLog: action.auditLog,
            };
        case SET_AUDITLOG_FILTER:
            return {
                ...state,
                filter: action.filter,
            }
        case LIST_EVENT_TYPES: {
            return {
                ...state,
                eventTypes: action.eventTypes,
            };
        }
        default:
            return state;
    }
}
