import React, { Component } from 'react';
import { Route, Routes } from 'react-router-dom'
import HelpMenu from './HelpMenu';
import Welcome from './Welcome';
import EdlevoActivities from './EdlevoActivities';
import LocalADGeneral from './LocalADGeneral';
import HelptNotFound from './HelpNotFound';
import SchoolAdminPrimarySchool from './SchoolAdminPrimarySchool';
import DnpHelp from './DnpHelp';


class Layout extends Component {
    render() {
        return (
            <div className="guides-row">
                <div className="nav-left">
                    <HelpMenu />
                </div>
                <div className="nav-right">
                    {this.props.children}
                </div>
            </div>
        )
    }
}

export default function HelpLayout() {
    return (
        <Routes>
            <Route path="edlevo/activities" element={<Layout><EdlevoActivities /></Layout>} />
            <Route path="localad/general" element={<Layout><LocalADGeneral /></Layout>} />
            <Route path="schooladmin/primarySchool" element={<Layout><SchoolAdminPrimarySchool /></Layout>} />
            <Route path="digitala-nationella-prov" element={<Layout><DnpHelp /></Layout>} />
            <Route path="/" element={<Layout><Welcome /></Layout>} />
            <Route path="*" element={<Layout><HelptNotFound /></Layout>} />
        </Routes>
    )
}
