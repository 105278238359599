import React, { Component } from 'react';
import UserModal from './Modal';
import { Input, FormGroup, Label, FormFeedback, Button } from 'reactstrap';
import { connect } from 'react-redux';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StatusCode from '../util/StatusCode';
import * as Helper from '../util/Helper';
import { getModules, updateLicenseKey, removeLicenseKey } from '../store/LicenseKeyAction';


const filterData = (newData, existingData) => {
    if (newData.status === StatusCode.COMPLETE && existingData !== undefined) {
        const filteredData = newData.data.filter(u => !existingData.some(cu => cu.id === u.id))
        return filteredData;
    }
    return newData.data;
}

const validate = (license) => {
    const validation = [];
    if (!license.description) {
        validation.push('description');
    }
    if (!license.validUntil) {
        validation.push('validUntil')
    }
    if (license.licenseModules.length === 0) {
        validation.push('licenseModules')
    }
    return validation;
}

class EditLicenseKey extends Component {
    state = {
        license: this.props.license,
        selected: undefined,
        remove: false,
        save: false
    }

    componentDidMount() {
        this.props.getModules(this.props.customerId);
    }

    selectedModule = (e) => {
        const moduleId = e.target.value;
        const module = this.props.modules.data.find(u => u.id === moduleId);
        this.setState({ selected: module })
    }

    removeModule = (moduleId) => {
        const { license } = this.state;
        const updates = license.licenseModules.filter(m => m.id !== moduleId)
        this.setState({ license: { ...this.state.license, licenseModules: updates } })
    }

    addModule = () => {
        const { selected, license } = this.state;
        const updates = [...license.licenseModules];
        updates.push(selected)

        this.setState({
            license: { ...this.state.license, licenseModules: updates },
            selected: undefined
        })
    }

    updateState = (e) => {
        var prop = e.target.name;
        var value = e.target.value;

        if (value === "") {
            value = null;
        }
        this.setState({ license: { ...this.state.license, [prop]: value } })
    }

    removeLicenseKey = async () => {
        await this.props.removeLicenseKey(this.props.license.id)
        if (this.props.remove.status === StatusCode.COMPLETE) {
            setTimeout(() => {
                this.setState({ remove: !this.state.remove })
                this.props.toggleModal();
                this.props.updateLicense();
            }, 1500)
        }
    }

    updateLicenseKey = async () => {
        const license = Helper.mergeWithUpdates(this.props.license, this.state.license);
        this.setState({ save: true })
        const isValid = validate(license).length === 0;

        const licensModules = [];
        license.licenseModules.forEach(lm => licensModules.push(lm.id))
        const updatedLicenseKey = { ...license, licenseModules: licensModules }

        if (isValid) {
            await this.props.updateLicenseKey(this.props.customerId, updatedLicenseKey );
            if (this.props.update.status === StatusCode.COMPLETE) {
                setTimeout(() => {
                    this.props.toggleModal();
                    this.props.updateLicense();
                }, 1500)
            }
        }
    }

    renderRemoveLicenseKey() {
        return (
            <UserModal
                header='Ta bort licens'
                body={`Vill du verkligen ta bort ${this.props.license.description}?`}
                isOpen={this.state.remove}
                toggleModal={() => this.setState({ remove: !this.state.remove })}
                update={this.removeLicenseKey}
                disabled={this.props.disabled}
                savingState={this.props.remove}
                cancel="Nej"
                confirm="Ja"
            />
        )
    }

    render() {
        const { modules, disabled, add } = this.props;
        const { remove, save, selected } = this.state;
        const license = Helper.mergeWithUpdates(this.props.license, this.state.license);
        const filteredModules = filterData(modules, license.licenseModules)
        const validation = validate(license);

        return (
            <React.Fragment>
                <UserModal
                    className="width600"
                    header={add ? 'Lägg till licensnyckel' : 'Uppdatera licensnyckel'}
                    body={<div>
                        <FormGroup>
                            <Label for="description">Beskrivning</Label>
                            <Input type="text" name="description" id="description" placeholder="Beskrivning" value={license.description || ''} disabled={this.props.disableInput} onChange={this.updateState} invalid={save && validation.indexOf('description') !== -1} autoComplete="off" />
                            <FormFeedback>Ange en beskrivning</FormFeedback>
                        </FormGroup>

                        <FormGroup>
                            <Label for="validUntil">Giltig till</Label>
                            <Input type="date" name="validUntil" id="validUntil" min={Helper.formatDate(new Date())} placeholder="Giltig till" value={Helper.formatDate(license.validUntil) || ''} disabled={this.props.disableInput} onChange={this.updateState} invalid={save && validation.indexOf('validUntil') !== -1} autoComplete="off" />
                            <FormFeedback>Ange ett datum</FormFeedback>
                        </FormGroup>

                        <Label>Modultyp</Label>
                        <ul className="permission-list">
                            {license.licenseModules.map(lm =>
                                <li key={lm.id} className={!lm.isReleased ? 'warning' : ''}>{lm.title} {!lm.isReleased && '(Ej produktionssatt)'}<button className="remove-permissionlevel" title="Radera licensnyckel" onClick={() => this.removeModule(lm.id)}><FontAwesomeIcon className="icon" icon="xmark-circle" /></button></li>)
                            }
                        </ul>

                        <div className="add-permission">
                            {(filteredModules && filteredModules.length > 0) ?
                                <FormGroup>
                                    <Input type="select" onChange={(e) => this.selectedModule(e)} value={(selected && selected.id) || ''} invalid={save && validation.indexOf('licenseModules') !== -1}>
                                        <option value='' disabled>Välj modul från listan</option>
                                        {filteredModules.map(m =>
                                            <option key={m.id} value={m.id} className={!m.isReleased ? 'warning': ''}>{m.title} {!m.isReleased && '(Ej produktionssatt)'}</option>
                                        )}
                                    </Input>
                                    <FormFeedback>Välj minst en modul</FormFeedback>
                                </FormGroup> : 'Moduler saknas'}
                            <Button className="btn-primary" onClick={this.addModule}>Lägg till</Button>
                        </div>

                        {!add && <button className="remove-user" onClick={() => this.setState({ remove: !remove })}>Ta bort licensnyckel</button>}

                    </div>}
                    isOpen={this.props.isOpen}
                    toggleModal={() => this.props.toggleModal()}
                    update={this.updateLicenseKey}
                    disabled={disabled}
                    savingState={this.props.update}
                    cancel="Avbryt"
                    confirm="Spara"
                />

                {remove && this.renderRemoveLicenseKey()}

            </React.Fragment>
        )
    }
}

const mapStateToProps = state => ({
    modules: state.license.modules,
    update: state.license.update,
    remove: state.license.deleteLicense
});

export default connect(mapStateToProps, { getModules, updateLicenseKey, removeLicenseKey })(EditLicenseKey);
