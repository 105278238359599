import React, { useEffect, useState } from "react"
import { GetModules } from './store/InstanceAction';
import { useSelector, useDispatch } from 'react-redux';
import { Container, Button, Row, Col } from 'reactstrap';
import TableHorizontal from './misc/TableHorizontal';
import * as Helper from './util/Helper';
import StatusCode from './util/StatusCode';
import SelectInput from './misc/SelectInput';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Result, UserAuthorized } from "./models/CoreModels";
import UnAuthorized from './UnAuthorized';

interface Modules {
    customerTitle: string
    customerUrl: string
    id: string
    moduleActive: boolean
    moduleId: string
    moduleNextScheduledStart: string
    moduleStartedAt: string
    moduleStatus: number
    moduleTitle: string
    moduleTypeName: string
}

const moduleStatus = (status: number): string => {
    switch (status) {
        case 0: return "Ingen";
        case 1: return "Körs";
        case 2: return "Färdig";
        case 3: return "Avslutades med fel";
        case 4: return "Avslutades med karantän";
        case 5: return "Körningen avbröts";
        default: return status.toString();
    }
}

const getModuleStatusIcon = (status: number) => {
    switch (status) {
        case 1: return <FontAwesomeIcon title={moduleStatus(status)} style={{ fontSize: '18px' }} icon="arrows-rotate" className="green" />; 
        case 2: return <FontAwesomeIcon title={moduleStatus(status)} style={{ fontSize: '18px' }} icon="check-circle" className="green" />; 
        case 3: return <FontAwesomeIcon title={moduleStatus(status)} style={{ fontSize: '18px' }} icon="times-circle" className="red" />;
        case 4: return <FontAwesomeIcon title={moduleStatus(status)} style={{ fontSize: '18px' }} icon="stop-circle" className="red" />;
        case 5: return <FontAwesomeIcon title={moduleStatus(status)} style={{ fontSize: '18px' }} icon="xmark" className="red" />;
        default: return null;
    }
}

const distinct = (value: any, index: number, self: any[]) => {
    if (value !== null) {
        return self.indexOf(value) === index;
    }
}

interface InstanceState {
    modules: Result<Modules[]>
}

interface UserAuthorizedState {
    userAuthorized: UserAuthorized
}

interface State {
    instance: InstanceState
    auth: UserAuthorizedState
}

type SelectedFilter = {
    customerTitle?: string
    moduleTypeName?: string
    moduleActive?: boolean
    moduleStatus?: number
}

type Filter = {
    customerTitle: string[]
    moduleTypeName: string[]
    moduleActive: boolean[]
    moduleStatus: number[]
}

const ListModules = () => {
    const dispatch = useDispatch();
    const modules = useSelector<State, Result<Modules[]>>(state => state.instance.modules);
    const user = useSelector<State, UserAuthorized>(state => state.auth.userAuthorized)

    const [filter, setFilter] = useState<Filter>({ customerTitle: [], moduleTypeName: [], moduleActive: [], moduleStatus: [], });
    const [hasFilter, setHasFilter] = useState(false);
    const [selectedFilter, setSelectedFilter] = useState<SelectedFilter>({});

    useEffect(() => {
        if (user.eventfulEmployee) {
            GetModules()(dispatch);
        }
    }, [dispatch])

    useEffect(() => {
        if (modules.status === StatusCode.COMPLETE && !hasFilter) {
            let customerTitle = modules.data.map(x => x.customerTitle).filter(distinct);
            let moduleTypeName = modules.data.map(x => x.moduleTypeName).filter(distinct);
            let moduleActive = modules.data.map(x => x.moduleActive).filter(distinct);
            let moduleStatus = modules.data.map(x => x.moduleStatus).filter(distinct);

            setFilter({ customerTitle, moduleTypeName, moduleActive, moduleStatus });
            setHasFilter(true);
        }
    }, [modules])

    const updateFilter = (name: string, value: boolean | string | number | undefined) => {
        setSelectedFilter({ ...selectedFilter, [name]: value });
    }

    const renderFilter = () => (<>
        <Row>
            <Col md="6">
                <SelectInput
                    name="customerTitle"
                    defaultText="Visa alla"
                    selected={selectedFilter.customerTitle}
                    items={filter.customerTitle?.map(x => ({ value: x, text: x }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    disabled={!hasFilter}
                    label="Kund"
                />
            </Col>
            <Col md="6">
                <SelectInput
                    name="moduleTypeName"
                    defaultText="Visa alla"
                    selected={selectedFilter.moduleTypeName}
                    items={filter.moduleTypeName?.map(x => ({ value: x, text: x }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    disabled={!hasFilter}
                    label="Modultyp"
                />
            </Col>
        </Row>

        <Row className="align-items-end">
            <Col md="6">
                <SelectInput
                    name="moduleStatus"
                    defaultText="Visa alla"
                    selected={selectedFilter.moduleStatus}
                    items={filter.moduleStatus?.map(x => ({ value: Number(x), text: moduleStatus(x) }))}
                    onChange={(name, value) => updateFilter(name, value)}
                    disabled={!hasFilter}
                    label="Status"
                    disableSort={true}
                    defaultValue={Number(-1)}
                    type="number"
                />
            </Col>
            <Col >
                <SelectInput
                    name="moduleActive"
                    defaultText="Visa alla"
                    selected={selectedFilter.moduleActive !== undefined ? Number(selectedFilter.moduleActive) : undefined}
                    items={filter.moduleActive?.map(x => ({ value: Number(x), text: Helper.active(x) }))}
                    onChange={(name, value) => updateFilter(name, value !== undefined ? Boolean(value) : undefined)}
                    disabled={!hasFilter}
                    label="Aktiva"
                    disableSort={true}
                    defaultValue={Number(-1)}
                    type="number"
                />
            </Col>
            <Col md="auto">
                <Button className="mb-3" style={{ 'marginRight': 0 }} onClick={() => setSelectedFilter({})}>Rensa filter</Button>
            </Col>
        </Row>

    </>);

    const renderModules = () => {
        let list = modules.data?.filter(x => selectedFilter.customerTitle ? selectedFilter.customerTitle === x.customerTitle : x.customerTitle)
            .filter(x => selectedFilter.moduleTypeName ? selectedFilter.moduleTypeName === x.moduleTypeName : x.moduleTypeName)
            .filter(x => selectedFilter.moduleStatus ? selectedFilter.moduleStatus === x.moduleStatus : true)
            .filter(x => selectedFilter.moduleActive !== undefined ? selectedFilter.moduleActive === x.moduleActive : true)
            .map(x => ({
                id: x.moduleId,
                data: [
                    { value: <a href={x.customerUrl} target="_blank" rel="noreferrer">{x.customerTitle}</a> },
                    { value: <a href={`${x.customerUrl}/module/${x.moduleId}/status`} target="_blank" rel="noreferrer">{x.moduleTitle}</a> },
                    { value: Helper.active(x.moduleActive) },
                    { value: x.moduleTypeName },
                    { value: Helper.formatDateTime(x.moduleStartedAt), className: 'nobreak'},
                    { value: getModuleStatusIcon(x.moduleStatus) },
                ]
            })) ?? [];

        return (
            <TableHorizontal
                header={[{ value: 'Kund' }, { value: 'Titel' }, { value: 'Aktiv' }, { value: 'Modultyp' }, { value: 'Senaste körning' }, { value: 'Status' }]}
                info={list}
                error={modules.status === StatusCode.ERROR}
                searching={modules.status === StatusCode.PENDING}
                noResMsg="Hittade inga matchande moduler. Testa att ändra filtret."
            />
        );
    };

    return (
        <div className="page">
            <Container>
                {user.eventfulEmployee ?
                    <>
                        <h1>Modulöversikt</h1>
                        {renderFilter()}
                        {renderModules()}
                    </>
                    : <UnAuthorized />}
            </Container>
        </div>
    );
}

export default ListModules;
