import React from 'react';
import { Container } from 'reactstrap';

export const PrivacyPolicy = () => (
    <Container>
        <h1>Privacy Policy Eventful FlowSync</h1>

        <h2>How do we access your data?</h2>
        <p>We access the data you provide us with which is strictly necessary in order to make the application function properly. We carefully evaluate and define the purposes of any personal data processing before launching a project. We will ensure that the personal data we collect are relevant, adequate and not excessive in relation to the purpose of the processing and its eventual use (e.g. insights, marketing, promotions). This means that only necessary and relevant information for the purpose sought can be collected and processed.</p>

        <h2>How do we use your data?</h2>
        <p>We will only use Personal Data on the basis of a legal ground:</p>
        <ul>
            <li>If necessary to perform a contract with the Data Subject (e.g. our employees, contractors, clients, suppliers); or</li>
            <li>If required to comply with a legal obligation (e.g. when we need to satisfy our obligations as employer); or</li>
            <li>Where we have a legitimate business need or a legitimate business reason to use Personal Data as part of our business activities (e.g. when carrying out a processing to better know our clients and send them promotional offers) ; or</li>
            <li>Where we have obtained the Data Subject’s Informed Consent when it is specifically required by law or by applicable policy. This may notably be the case where none of the other legal grounds described above is applicable and to the extent permitted under applicable law.</li>
        </ul>                
        <h2>How do we store your data?</h2>
        <p>Our application stores your data in Azure SQL, the Microsoft cloud-hosted database. We store only the data that is absolutely necessary for our application to function in its entirety. Deleting some of this data will interrupt our application functionality. However, if you do not want us to store your data, you can send us your request to support@eventful.se and we will permanently delete it from our database.</p>

        <h2>How do we share your data?</h2>
        <p>We will never transfer, sell, make copies, or share any of your data stored by the application to third party services or companies unless strictly necessary to enable the application to function properly. Accordingly, personal data will be disclosed to third parties on a strictly limited 'need to know' basis where there is clear justification for transferring Personal Data - either because the Data Subject has consented to the transfer or because disclosure is required to perform a contract to which the Data Subject is a party, or for a legitimate purpose that does not infringe the Data Subject's fundamental rights, including the right to privacy (e.g. sharing in the context of a merger and acquisition operation).</p>
    </Container>
);