import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_USER = 'GET_USER';


export const clearGetUser = () => dispatch => {
    dispatch({ type: GET_USER, user: { code: StatusCode.NONE } });
}

export const getUser = ({ customerId, customerTenant, searchString }) => dispatch => {
    dispatch({ type: GET_USER, user: { code: StatusCode.PENDING } });

    const url = `api/v1/userpicker/${customerId}?customerTenant=${customerTenant}&searchString=${searchString}`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_USER,
                user: { code: StatusCode.COMPLETE, data: res.data },
            })
        })
        .catch(error => {
            dispatch({
                type: GET_USER,
                user: { code: StatusCode.ERROR, error }
            })
        })
};

const initialState = {
    user: { code: StatusCode.NONE },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_USER:
            return {
                ...state,
                user: action.user,
            }
        default:
            return state;
    }
}