import React from 'react';
import { Button } from 'reactstrap';
import { useNavigate } from 'react-router-dom';


const HelptNotFound = () => {
    const navigate = useNavigate();

    const navigateToHelp = () => {
        navigate('/help', { replace: true });
    }

    return (
        <div className="text-center mt-4">
            <h2>Ledsen, sidan du letar efter hittades inte.</h2>
            <p>Det kanske är fel url eller så finns inte sidan du letar efter längre.</p>
            <Button onClick={navigateToHelp} color="flowsync" className="mt-4">Till hjälpavsnitt</Button>
        </div>
    )
}

export default HelptNotFound;
