import React from 'react';
import { Route, Routes } from 'react-router-dom'
import Home from './components/Home';
import Instances from './components/Instances';
import ListModules from './components/ListModules';
import { PrivacyPolicy } from './components/PrivacyPolicy';
import About from './components/About';
import Layout from './components/Layout';
import { } from './components/Styles/CssFiles';
import Private from './components/Private';
import Permissions from './components/CustomerUserPermissions';
import PermissionGroups from './components/PermissionGroups';
import LicenseKeys from './components/LicenseKeys';
import AuditLog from './components/AuditLog';
import HelpLayout from './components/help/HelpLayout';
import Consumers from './components/Consumers';
import NotFound from './components/NotFound';
import AppTokenConsentFeedback from './components/AppTokenConsentFeedback'

import { library } from "@fortawesome/fontawesome-svg-core";
import { faCheckCircle, faStopCircle, faXmark, faArrowsRotate, faXmarkCircle, faQuestionCircle, faExclamationCircle, faChevronLeft, faChevronRight, faPen, faThumbsUp, faRocket, faFolderOpen, faBars, faKey, faUserLock, faList, faUser, faRightFromBracket, faRightToBracket, faPlus, faTrash, faEllipsisVertical, faCheck, faCrown } from '@fortawesome/free-solid-svg-icons';
import { faGoogle, faMicrosoft } from '@fortawesome/free-brands-svg-icons';
library.add(faCheckCircle, faStopCircle, faXmark, faArrowsRotate, faXmarkCircle, faQuestionCircle, faExclamationCircle, faChevronLeft, faChevronRight, faPen, faGoogle, faMicrosoft, faThumbsUp, faRocket, faFolderOpen, faBars, faKey, faUserLock, faList, faUser, faRightFromBracket, faRightToBracket, faPlus, faTrash, faEllipsisVertical, faCheck, faCrown);


const PrivateLayout = (props) => {
    return <Layout><Private>{props.children}</Private></Layout>;
}


export default function App() {
    return (
        <Routes>
            <Route exact path='/' element={<Layout><Home /></Layout>} />
            <Route path='/instances' element={<PrivateLayout><Instances /></PrivateLayout>} />
            <Route path='/modules' element={<PrivateLayout><ListModules /></PrivateLayout>} />
            <Route exact path='/permissions/:customerId' element={<PrivateLayout><Permissions /></PrivateLayout>} />
            <Route exact path='/license/:customerId' element={<PrivateLayout><LicenseKeys /></PrivateLayout>} />
            <Route exact path='/auditlog/:customerId' element={<PrivateLayout><AuditLog /></PrivateLayout>} />
            <Route path='/permissiongroups' element={<PrivateLayout><PermissionGroups /></PrivateLayout>} />
            <Route path='/consumers' element={<PrivateLayout><Consumers /></PrivateLayout>} />
            <Route path='/privacyPolicy' element={<Layout><PrivacyPolicy /></Layout>} />
            <Route exact path='/about' element={<Layout><About /></Layout>} />
            <Route path='/help/*' element={<Layout><HelpLayout /></Layout>} />
            <Route path='/apptoken/consentfeedback' element={<Layout><AppTokenConsentFeedback /></Layout>} />
            <Route path='*' element={<Layout><NotFound /></Layout>} />
        </Routes>
    )
}
