import React from 'react';
import { Col, Row, Container } from 'reactstrap';
import logos from './logos/Logos';

const About: React.FC = () => (<div className="about">
    <div className="hero">
        <div className="bg">
            <div className="text">
                <h1>Om FlowSync</h1>
            </div>
        </div>
    </div>

    <div className="content bg">
        <Row className="content_box">
            <Col sm="4" className="text right">
                <h3>Enkelhet</h3>
                <p>FlowSync erbjuder ett administrationsverktyg för konfigurationen av era integrationer vilket ger er möjlighet att dels se
                    hur integrationen är konfigurerad och dels möjligheter till att justera konfigurationen.</p>
                <p>Detta är användbart eftersom det ger er, systemförvaltare, möjlighet att själva administrera era integrationer baserat på hur
                    er organisation och deras krav förändras över tid.</p>
            </Col>
            <Col sm="8" className="image">
                <img src="./FlowSync-create-module-1500.png" alt="" />
            </Col>
        </Row>

        <Row className="content_box reverse">
            <Col sm="8" className="image">
                <img src="./FlowSync-user.png" alt="" />
            </Col>
            <Col sm="4" className="text left">
                <h3>Transparens</h3>
                <p>FlowSync ger en djup insikt i vilken information som lästs in från era register och ger även insikt i var denna information har
                    skickats så att ni känner att ni har kontroll över var er information har synkroniserats.</p>
                <p>Ett exempel på när detta är användbart är för att undersöka att rätt elever har tillgång till rätt digitala resurser,
                    såsom digitalt klassrum och läromedelstjänster.</p>
            </Col>
        </Row>

        <Row className="content_box">
            <Col sm="4" className="text right">
                <h3>Snabbhet</h3>
                <p>FlowSync är en integrationsmotor som bryter trenden kring att synkroniseringar från elevregister körs en gång per dag till att
                    låta er köra integrationer så ofta som ni känner att det är nödvändigt. Dels kan man snabbt och enkelt genomföra icke-schemalagda
                    körningar via gränssnittet, men ni kan även själva styra över hur schemaläggningen av era synkroniseringar ska köras.</p>
                <p>Ett exempel på när detta kan vara användbart är när en vikarie behöver sättas in. Vikarien behöver då få tillgång till rätt information
                    så snart som möjligt. Ni uppdaterar helt enkelt i ert register och kör sedan en synkronisering, vikarien får då tillgång till rätt
                    digitala klassum och elevernas undervisning påverkas minimalt.</p>
            </Col>
            <Col sm="8" className="image">
                <img src="./FlowSync-oneroster.png" alt="" />
            </Col>
        </Row>

        <Row className="content_box reverse">
            <Col sm="8" className="image">
                <img className="image-bg" src="./flowsync_flow.png" alt="" />
            </Col>
            <Col sm="4" className="text left">
                <h3>Framtidssäker</h3>
                <p>FlowSync är framtidssäker eftersom den är byggd från grunden med vår svenska standard <a href="https://www.sis.se/produkter/informationsteknik-kontorsutrustning/ittillampningar/ittillampningar-inom-utbildning/ss-120002020/" target="_blank" rel="noopener noreferrer"> SS12000</a> i botten.
                    Dock är SS12000 inte den
                    enda standarden som inbyggd i FlowSync, vi har även byggt in stöd för den internationella standarden <a href="https://www.imsglobal.org/oneroster-v11-final-specification" target="_blank" rel="noopener noreferrer">OneRoster</a> och självklart
                    leverantörsoberoende standarder såsom <a href="https://sds.microsoft.com/" target="_blank" rel="noopener noreferrer">Microsoft School Data Sync</a> och
                    <a href="https://developers.google.com/classroom" target="_blank" rel="noopener noreferrer"> Google Classroom API.</a></p>
                <p>Vi går mer och mer mot en värld där det är viktigt att koppla samman system med varandra. FlowSync blir en naturlig del i att koppla
                    samman skolans alla system så att rätt information finns i alla skolans system.</p>
            </Col>
        </Row>
    </div>

    <Row className="content_box_vertical logotypes">
        <Container>
            <div className="text">
                <h3>Systempussel</h3>
                <p>När man pratar om integrationer så dyker såklart även frågan upp kring vilka system som kan hanteras. Vi på Eventful kan läsa ut
                    från de ledande elevregistren och schemasystemen på den svenska marknaden och även skicka vidare information till många av de
                    svenska lärplattformarna samt många läromedelsleverantörer.</p>
                <p>Här finns även en matrisbild över alla samarbetspartners logotyper. Alltså de samarbetspartners som vi har stöd för i FlowSync.</p>
            </div>
            <div className="logos">
                {logos.map((logo, i) => {
                    return (
                        <div key={i}>
                            <img className="logo" src={logo.img} alt={logo.name} />
                        </div>
                    )
                }
                )}
            </div>
        </Container>
    </Row>

    <Row className="contact">
        <Container>
            <div className="text">
                <h3>Kontakta oss</h3>
                <p>Om ni tycker att detta låter som något som ni vill veta ännu mera om eller om ni helt enkelt vill blir en av Eventfuls alla nöjda
                    kunder så kontakta gärna någon på vår säljavdelning så vi snabbt se till att ni blir en del av Eventful-familjen!</p>
                <div className="btn-box">
                    <a href="https://www.eventful.se/om-oss/" target="_blank" rel="noopener noreferrer" className="btn">Kontakta oss</a>
                </div>
            </div>
        </Container>
    </Row>
</div>);

export default About;
