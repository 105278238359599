import React, { Component } from 'react';
import { Container, Button } from 'reactstrap';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Loading from './misc/Loading';
import { ListInstances, GetStatus } from './store/InstanceAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

class Instances extends Component {
    state = {
        isOpen: true
    };

    async componentDidMount() {
        let instances = await this.props.ListInstances(false);
        instances.forEach(instance => this.getStatus(instance.customerId))
    };

    getStatus = (customerId) => {
        this.props.GetStatus(customerId);
    };

    renderInstances() {
        const { instances, instanceStatuses } = this.props;

        const moduleStatus = (status) => {
            if (status === 'error') {
                return <FontAwesomeIcon icon="question-circle" className="icon_active" title="Status saknas" />
            } else {
                if (status.hasModuleInQuarantine) {
                    return <FontAwesomeIcon icon="stop-circle" className="red icon_active" title="Minst en modul är i karantän" />;
                } else {
                    var iconState = status.iconState;
                    if (iconState === 4 || iconState === 3)
                        return <FontAwesomeIcon icon="xmark-circle" className="red icon_active" title="Minst en modul har error" />;
                    if (iconState === 2)
                        return <FontAwesomeIcon icon="exclamation-circle" className="yellow icon_active" title="Minst en modul har varning" />;
                    if (iconState === 1)
                        return <FontAwesomeIcon icon="check-circle" className="green icon_active" title="Alla moduler har gått bra" />;
                }
            }
            return <FontAwesomeIcon icon="question-circle" className="icon_active" title="Status saknas" />
        };

        const renderModuleCount = (status) => {
            if (status === 'error') {
                return <p>Status saknas</p>
            } else {
                return (
                    <div>
                        <p>{moduleCount(status.modules.length)}</p>
                    </div>
                )
            }
        }

        const moduleCount = (length) => {
            if (length === 1)
                return (`${length} modul`)
            if (length > 1)
                return (`${length} moduler`)
            return `Moduler saknas`
        };

        if (instances === undefined)
            return <Loading />;
        if (instances.length === 0)
            return 'Du saknar instanser'
        return (
            <div className="instance-container">
                {instances.map((instance, i) => {
                    var status = instanceStatuses[instance.customerId];
                    return (
                        <div className="instance-single" key={i}>
                            <div className="is_header">
                                <h5><a href={instance.url} target="_blank" rel="noreferrer">{instance.title}</a></h5>
                                <span>{status ? moduleStatus(status) : ''}</span>
                            </div>
                            <div className="is_body">
                                <p><a href={instance.url} target="_blank" rel="noreferrer">{instance.url}</a></p>
                                <span>{status ? renderModuleCount(status) : <Loading class='spinnerSmall' />}</span>
                            </div>
                            <div className="is_footer">
                                <Link to={`/permissions/${instance.customerId}`}><FontAwesomeIcon icon="user-lock" title="Behörighet" /></Link>
                                <Link to={`/license/${instance.customerId}`}><FontAwesomeIcon icon="key" title="Licensnycklar" /></Link>
                                <Link to={`/auditlog/${instance.customerId}`}><FontAwesomeIcon icon="list" title="Granskningslogg" /></Link>
                            </div>
                        </div>
                    )
                })}
            </div>
        )
    }

    render() {
        return (
            <div className="page instances">
                <div>
                    <Container>
                        <span className="d-flex justify-content-between align-items-center">
                            <h1 className="inline">Instanser</h1>
                            {this.props.user.eventfulEmployee && <Link to="/modules"><Button style={{ marginRight: 0 }}>Modulöversikt</Button></Link>}
                        </span>
                    </Container>
                </div>
                <Container>
                    {this.renderInstances()}
                </Container >
            </div >
        );
    }
}


const mapStateToProps = state => ({
    user: state.auth.userAuthorized,
    needLogin: state.auth.needLogin,
    instances: state.instance.instances,
    instanceStatuses: state.instance.instanceStatuses
});

export default connect(mapStateToProps, { ListInstances, GetStatus })(Instances);
