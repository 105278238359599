import React from 'react';
import UserModal from './Modal';
import { createCustomerPermissionUpdate } from '../util/CustomerPermissionHelper';


const RemoveCustomerUser = (props) => {

    const removeUser = () => {
        const updates = createCustomerPermissionUpdate([props.user], []);
        props.update(updates);
    }

    return (
        <UserModal
            header='Radera behörigheter'
            body={`Vill du ta bort behörigheterna för ${props.user.displayName}?`}
            isOpen={props.isOpen}
            toggleModal={() => props.toggleModal()}
            update={removeUser}
            disabled={props.disabled}
            savingState={props.savingState}
            cancel="Nej"
            confirm="Ja"
        />
    )
}

export default RemoveCustomerUser;
