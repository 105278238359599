import axios from 'axios';
import MsalApi from './MsalApi';
import GoogleApi from './GoogleApi';
import store from '../store/store';
import { RESET_AUTH_STATUS } from '../store/AuthAction';


const getToken = async () => {
    const type = localStorage.getItem('AuthAuthority');
    switch (type) {
        case 'google':
            try {
                const token = await GoogleApi.token();
                return token;
            } catch (err) {
                console.warn("User must sign in");
                store.dispatch({ type: RESET_AUTH_STATUS });
                return;
            }
        case 'microsoft':
            try {
                const tokenResponse = await MsalApi.idTokenSilent();
                return tokenResponse.idToken.rawIdToken;
            }
            catch (err) {
                console.warn("User must sign in");
                store.dispatch({ type: RESET_AUTH_STATUS });
                return;
            }
        default:
            console.warn("No auth authority has been chosen");
            store.dispatch({ type: RESET_AUTH_STATUS });
            return;
    }
};

class HttpClient {

    static get(url, params = undefined) {
        return HttpClient.send({
            method: 'GET',
            url: url,
            params: params
        });
    }

    static post(url, body = undefined) {
        return HttpClient.send({
            method: 'POST',
            url: url,
            data: body
        });
    }

    static put(url, body = undefined) {
        return HttpClient.send({
            method: 'PUT',
            url: url,
            data: body
        });
    }

    static patch(url, params = undefined, body = undefined) {
        return HttpClient.send({
            method: 'PATCH',
            url: url,
            data: body,
            params: params
        });
    }

    static delete(url, params = undefined, body = undefined) {
        return HttpClient.send({
            method: 'DELETE',
            url: url,
            data: body,
            params: params
        });
    }

    static async send(request) {
        var token = await getToken();

        if (token) {
            if (!request.headers) {
                request.headers = {};
            }

            request.headers.Authorization = `Bearer ${token}`;
        }

        return await axios(request);
    }
}

export default HttpClient;