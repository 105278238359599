export const createCustomerPermissionUpdate = (items, permissionLevels) => {
    const updates = {
        users: [],
        groups: [],
    }

    items.forEach(item => {
        switch (item.objectType) {
            case 'user':
                updates.users.push({
                    externalId: item.id,
                    email: item.email,
                    displayName: item.displayName,
                    roleName: permissionLevels,
                });
                break;
            case 'group':
                updates.groups.push({
                    groupId: item.id,
                    roleName: permissionLevels,
                });
                break;
            default:
                break;
        }
    })

    return updates;
}