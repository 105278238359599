import React, { CSSProperties } from 'react';


interface LoadingProps {
    class?: string
    center?: string
    style?: CSSProperties
}

const Loading: React.FC<LoadingProps> = (props: LoadingProps) =>

    <div className={props.center} style={props.style}>
        <div className={props.class + ' spinner spinner-slow'}></div>
    </div>

export default Loading;
