import React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button } from 'reactstrap';
import SavingState from '../misc/SavingState';


const UserModal = (props) => {
    return (
        <Modal className={props.className ? props.className : ''} isOpen={props.isOpen} toggle={props.toggleModal}>
            <ModalHeader toggle={props.toggleModal}>
                {props.header}
            </ModalHeader>
            <ModalBody>
                {props.body}
            </ModalBody>
            <ModalFooter>
                <Button onClick={props.toggleModal}>{props.cancel}</Button>
                <Button className="btn-primary" disabled={props.disabled} onClick={props.update}>{props.confirm}</Button>
                <SavingState status={props.savingState.status}/>
            </ModalFooter>
        </Modal>
    )
}

export default UserModal;
