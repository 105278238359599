import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import thunk from 'redux-thunk';
import { routerReducer, routerMiddleware } from 'react-router-redux';
import * as AuthAction from './AuthAction';
import * as InstanceAction from './InstanceAction';
import * as CustomerPermissionsAction from './CustomerPermissionsAction';
import * as GroupPermissionAction from './GroupPermissionsAction';
import * as LicenseKeyAction from './LicenseKeyAction';
import * as AuditLogAction from './AuditLogAction';
import * as UserPickerAction from './UserPickerAction';
import * as ConsumerAction from './ConsumerAction';

function configureStore(history, initialState) {
    const reducers = {
        auth: AuthAction.reducer,
        instance: InstanceAction.reducer,
        customerPermission: CustomerPermissionsAction.reducer,
        groups: GroupPermissionAction.reducer,
        license: LicenseKeyAction.reducer,
        auditLog: AuditLogAction.reducer,
        userpicker: UserPickerAction.reducer,
        consumer: ConsumerAction.reducer,
    };

    const middleware = [
        thunk,
        routerMiddleware(history)
    ];

    // In development, use the browser's Redux dev tools extension if installed
    const enhancers = [];
    const isDevelopment = process.env.NODE_ENV === 'development';
    if (isDevelopment && typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION__) {
        enhancers.push(window.__REDUX_DEVTOOLS_EXTENSION__());
    }

    const rootReducer = combineReducers({
        ...reducers,
        routing: routerReducer
    });

    return createStore(
        rootReducer,
        initialState,
        compose(applyMiddleware(...middleware), ...enhancers)
    );
}

export default configureStore(window.initialReduxState);

