import React from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';


const NotFound = () => {
    return (
        <div className="login">
            <Container>
                <h2>Ledsen, sidan du letar efter hittades inte.</h2>
                <p>Det kanske är fel url eller så finns inte sidan du letar efter längre.</p>
                <div className="btn-box">
                    <Link to="/" className="btn">Till startsidan</Link>
                </div>
            </Container>
        </div>
    );
}

export default NotFound;
