import React, { Component } from 'react';
import { Button, Input, FormGroup, Form } from 'reactstrap';
import { connect } from 'react-redux';
import { setServiceAccount, GetInstance } from '../store/InstanceAction';
import SavingState from '../misc/SavingState';
import StatusCode from '../util/StatusCode';


class ConsentGoogle extends Component {
    state = {
        serviceAccount: '',
        updatedServiceAccount: { status: StatusCode.NONE },
    }

    async componentDidMount() {
        await this.props.GetInstance(this.props.customer.customerId, false);
        this.setState({ serviceAccount: this.props.customerProps.data.serviceAccount })
    }

    setServiceAccount = (e) => {
        this.setState({ serviceAccount: e.target.value, updatedServiceAccount: { status: StatusCode.NONE } });
    }

    updateServiceAccount = async (e) => {
        e.preventDefault();
        var res = await this.props.setServiceAccount(this.props.customer.customerId, this.state.serviceAccount);
        let result = StatusCode.PENDING;
        if (res.status === 200) {
            result = StatusCode.COMPLETE;
        } else {
            result = StatusCode.ERROR;
        }
        this.setState({ updatedServiceAccount: { status: result } });
    }

    render() {
        return (
            <div>
                <p><b>Ge oss åtkomst genom att följa nedanstående instruktion:</b></p>
                <ol>
                    <li>Ange ett servicekonto</li>
                    <Form onSubmit={this.updateServiceAccount}>
                        <FormGroup className="serviceAccount">
                            <Input type="email" name="service_account" placeholder="Ange ett servicekonto" onChange={this.setServiceAccount} value={this.state.serviceAccount || ''} autoComplete="off" />
                            <Button className="btn-primary" disabled={this.props.disabled} onClick={this.updateServiceAccount}>Spara</Button>
                        </FormGroup>
                        {SavingState(this.state.updatedServiceAccount)}
                    </Form>
                    <li>Ge vår applikation behörighet i er Google-miljö
                        <ol>
                            <li>Gå till: <a href="https://admin.google.com/ac/owl/domainwidedelegation" target="_blank">https://admin.google.com/ac/owl/domainwidedelegation</a></li>
                            <li>Klicka på "Lägg till ny"</li>
                            <li>Fyll i Klient Id: {this.props.data.clientId}</li>
                            <li>Fyll i följande domänomfattningar:
                                <ul>
                                    {this.props.data.scopes.map((scope, i) =>
                                        <li key={i}>{scope}</li>
                                    )}
                                </ul>
                            </li>
                        </ol>
                    </li>
                </ol>
            </div>
        );
    }
}


const mapStateToProps = state => ({
    customerProps: state.instance.instance,
});

export default connect(mapStateToProps, { setServiceAccount, GetInstance })(ConsentGoogle);
