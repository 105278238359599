import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import store from './components/store/store';
import App from './App';
import * as AppTokenUtil from './components/util/AppTokenUtil.js'

AppTokenUtil.handleAuthWindow();

const root = createRoot(document.getElementById('root'));


root.render(
    <Provider store={store}>
        <BrowserRouter>
            <App />
        </BrowserRouter>
    </Provider>
  );


