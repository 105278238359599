import React, { Component } from 'react';
import { connect } from 'react-redux';
import * as AppTokenUtil from '../util/AppTokenUtil';
import { Button } from 'reactstrap';


class ConsentMicrosoft extends Component {
    state = {
        adminConsent: undefined,
        accessDenied: false,
        error: false,
    }

    login = async () => {
        this.setState({ error: false, adminConsent: undefined, accessDenied: false })

        let uri = this.props.data.uri;
        let login = await AppTokenUtil.authWindow(uri);

        if (login.admin_consent === 'True') {
            this.setState({ adminConsent: true });
        } else if (login.error === 'access_denied') {
            this.setState({ accessDenied: true });
        } else {
            this.setState({ error: true });
        }
    }

    render() {
        const { adminConsent, accessDenied, error } = this.state;

        if (adminConsent) {
            return (
                <div>
                    <h5>Bekräftselse för godkännande av behörigher</h5>
                    <p>Det kan ta en liten stund innan det fungerar att söka efter en användare.</p>
                </div>);
        } else if (accessDenied) {
            return (
                <p>Behörighet saknas för att godkänna applikationen i denna tenantmiljö.</p>);
        } else if (error) {
            return (
                <div>
                    <p>Något gick fel. Testa igen.</p>
                    <Button className="btn-primary" disabled={this.props.disabled} onClick={this.login}>Logga in för att acceptera behörigheter</Button>
                </div>);
        } else {
            return (
                <div>
                    <p>Ge oss åtkomst genom att acceptera behörigheter här:</p>
                    <Button className="btn-primary" onClick={this.login}>Logga in för att acceptera behörigheter</Button>
                </div>
            )
        }
    }
}

const mapStateToProps = state => ({
})

export default connect(mapStateToProps, {})(ConsentMicrosoft);
