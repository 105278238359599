
export const formatDate = (date?: string): string => {
    if (date) {
        return date = new Date(date).toLocaleDateString('sv-SE')
    }
    return '';
}

export const formatDateTime = (date?: string): string => {
    if (date) {
        return date = new Date(date).toLocaleString('sv-SE')
    }
    return '';
}

export const active = (active: boolean): string => (active ? "Ja" : "Nej");

export const mergeWithUpdates = <T>(module: T, updates: Partial<T>) => {
    const merged = { ...module };
    (Object.keys(updates) as Array<keyof T>).forEach(key => {
        merged[key] = updates[key] as T[keyof T]; //NOTE: Forcing the type here is slightly unsafe but in this case it should not matter.
    });
    return merged;
};

export const formatText = (raw: string): string => {
    try {
        const json = JSON.parse(raw);
        if (typeof json === "object") {
            return JSON.stringify(json, null, 2)
        } else {
            return raw;
        }
    }
    catch (e) {
        return raw;
    }
}