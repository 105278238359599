import React from 'react';


const Welcome = () => {
    return (
        <div>
            <h1>Hjälpavsnitt</h1>
            <p>I det här biblioteket samlar vi hjälpavsnitt till FlowSync.</p>
        </div>
    )
}

export default Welcome;
