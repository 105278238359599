import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_LICENSEKEYS = 'GET_LICENSEKEYS';
const GET_MODULES = 'GET_MODULES';
const UPDATE_MODULE = 'UPDATE_MODULE';
const DELETE_LICENSE = 'DELETE_LICENSE';

export const getLicenseKeys = (customerId) => dispatch => {
    dispatch({
        type: GET_LICENSEKEYS,
        licensekeys: { status: StatusCode.PENDING }
    })
    const url = `api/v1/license/edit/${customerId}`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_LICENSEKEYS,
                licensekeys: {
                    status: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_LICENSEKEYS,
                licensekeys: {
                    status: StatusCode.ERROR,
                    error: err
                }
            })
        })
};

export const getModules = (customerId) => dispatch => {
    dispatch({
        type: GET_MODULES,
        modules: { status: StatusCode.PENDING }
    })
    const url = `api/v1/license/modules/${customerId}`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_MODULES,
                modules: {
                    status: StatusCode.COMPLETE,
                    data: res.data
                }
            })
        })
        .catch(err => {
            dispatch({
                type: GET_MODULES,
                modules: {
                    status: StatusCode.ERROR,
                    error: err
                }
            })
        })
}

export const updateLicenseKey = ( customerId, license ) => dispatch => {
    dispatch({
        type: UPDATE_MODULE, update: { status: StatusCode.PENDING }
    })
    const url = `api/v1/license/edit/${customerId}`;
    return HttpClient.put(url, license )
        .then(res => {
            dispatch({
                type: UPDATE_MODULE, update: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: UPDATE_MODULE, update: { status: StatusCode.ERROR }
            })
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: UPDATE_MODULE, update: { status: StatusCode.NONE }
                })
            }, 1500)
        })
}

export const removeLicenseKey = (licenseId) => dispatch => {
    dispatch({
        type: DELETE_LICENSE, deleteLicense: { status: StatusCode.PENDING }
    })
    const url = `api/v1/license/edit/${licenseId}`;
    return HttpClient.delete(url)
        .then(() => {
            dispatch({
                type: DELETE_LICENSE, deleteLicense: { status: StatusCode.COMPLETE }
            })
        })
        .catch(err => {
            dispatch({
                type: DELETE_LICENSE, deleteLicense: { status: StatusCode.ERROR }
            })
        })
        .finally(() => {
            setTimeout(() => {
                dispatch({
                    type: DELETE_LICENSE, deleteLicense: { status: StatusCode.NONE }
                })
            }, 1500)
        })
}

const initialState = {
    licensekeys: { status: StatusCode.NONE },
    modules: { status: StatusCode.NONE },
    update: { status: StatusCode.NONE },
    deleteLicense: { status: StatusCode.NONE }
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_LICENSEKEYS:
            return {
                ...state,
                licensekeys: action.licensekeys,
            }
        case GET_MODULES:
            return {
                ...state,
                modules: action.modules,
            }
        case UPDATE_MODULE:
            return {
                ...state,
                update: action.update,
            }
        case DELETE_LICENSE:
            return {
                ...state,
                deleteLicense: action.deleteLicense,
            }
        default:
            return state;
    }
}