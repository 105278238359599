import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import SignInModal from './SignInModal';


class Login extends Component {
    state = {
        isOpen: false
    }

    render() {
        const { isOpen } = this.state;

        return (
            <div className="login">
                <Container>
                    <h2>Du behöver logga in för att se den här sidan</h2>
                    <div className="btn-box">
                        <Link to="/" className="btn">Till startsidan</Link>
                        <div className="btn" onClick={() => this.setState({ isOpen: !isOpen })}> Logga in</div>
                    </div>
                </Container>
                {isOpen &&
                    <SignInModal
                        header='Logga in'
                        isOpen={isOpen}
                        toggleModal={() => this.setState({ isOpen: !isOpen })}
                    />}
            </div>
        );
    }
}

export default Login;
