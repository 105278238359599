import React from 'react';
import { Button } from 'reactstrap';

const LocalADGeneral = () => {
    return (
        <div>
            <h1>Konfiguration Lokalt AD</h1>
            <h3>Beskrivning</h3>
            <p>Modulen använder en CSV-fil (semikolonseparerade värden) som innehåller information om hur man kan identifiera en person, s.k. mappingskolumner, och datakolumner som innehåller information som ska kompletteras i FlowSync. CSV-filen måste innehålla minst en mappningskolumn och minst en datakolumn men kan såklart innehålla fler än en av varje kategori. Filen måste också innehålla minst en rad med innehåll i både en mappningskolumn och en datakolumn. Då används följande inbördes ordning mellan mappningskolumerna för att hitta rätt person i FlowSync:</p>
            <ul>
                <li>Matchning via kombinationen ExternalId / SystemName</li>
                <li>Matchning via HashedSocialnumber</li>
                <li>Matchning via Socialnumber</li>
            </ul>
            <p>CSV-filen ska sedan kontinuerligt laddas upp till den Azure Storage Container som konfigurationen pekar på. FlowSync kommer enligt schemaläggningen att läsa in den senast uppdaterade CSV-filen från denna plats.</p>
            <h3>Mappningskolumner</h3>
            <ul>
                <li>ExternalId - Ett värde som identifierar personen i ett källsystem</li>
                <li>SystemName - Ett värde som anger vilket källsystem användaren ska komma från</li>
                <li>HashedSocialnumber - Ett värde som är en SHA256 - checksumma av värdet YYYYMMDDXXXZXXXZYYYY
                    <ul>
                        <li>YYYY avser året i personnummret inklusive århundrade och årtusende - exempelvis 1977 eller 1980</li>
                        <li>MM avser månaden i ett personnummer - exempelvis 05 eller 11</li>
                        <li>DD avser dagen i månaden i ett personnummer - exempelvis 01 eller 19</li>
                        <li>XXXZ avser de fyra sista i ett personnummer - exempelvis 1234 eller TF55</li>
                    </ul>
                </li>
                <li>Socialnumber - Ett värde som är personnummer i klartext och i formatet YYYYMMDDXXXZ (denna bör ej användas utan använd med fördel HashedSocialnumber)
                    <ul>
                        <li>YYYY avser året i personnummret inklusive århundrade och årtusende - exempelvis 1977 eller 1980</li>
                        <li>MM avser månaden i ett personnummer - exempelvis 05 eller 11</li>
                        <li>DD avser dagen i månaden i ett personnummer - exempelvis 01 eller 19</li>
                        <li>XXXZ avser de fyra sista i ett personnummer - exempelvis 1234 eller TF55. Mappning av TF är ej skiftlägeskänslig</li>
                    </ul>
                </li>
            </ul>
            <h3>Datakolumner</h3>
            <ul>
                <li>WorkEmail - Användarens e-postadress som ska användas inom skolsammanhang. Detta fält läses in som en e-postadress av typen "elev" om personen har en aktiv inskrivning och "personal" om personen har en aktiv tjänstgöring</li>
                <li>PersonalEmail - Användarens privata e-postadress och behöver inte vara en adress som finns i skolans domän</li>
                <li>EducationStudentEmail – Användarens e-postadress av typen ”elev” som ska användas inom skolsammanhang</li>
                <li>EducationStaffEmail – Användarens e-postadress av typen ”personal” som ska användas inom skolsammanhang</li>
                <li>WorkOtherEmail – Användarens e-postadress som ska användas inom arbete som inte rör skola</li>
                <li>EducationStudentEduPersonPrincipalName - Användarens EPPN (EduPersonPrincipalName) av typen "elev"</li>
                <li>EducationStaffEduPersonPrincipalName - Användarens EPPN (EduPersonPrincipalName) av typen "personal"</li>
            </ul>

            <hr />
            <p>Värden avgränsas med semikolon (";")</p>
            <a href="/help-images/Local AD Example.csv" download><Button className="btn-primary">Ladda ner Exempel-CSV</Button></a>
        </div>
    )
}

export default LocalADGeneral;
