import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Container, Table, Button } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import * as PermissionLevel from './util/PermissionLevelUtil';
import Loading from './misc/Loading';
import { getLicenseKeys } from './store/LicenseKeyAction';
import EditLicenseKey from './modules/EditLicenseKey';
import StatusCode from './util/StatusCode';
import * as Helper from './util/Helper';


const initialLicense = {
    id: undefined,
    description: '',
    validUntil: '',
    licenseModules: []
}

const LicenseKeys = () => {
    const { customerId } = useParams();
    const dispatch = useDispatch();

    const [edit, setEdit] = useState(false);
    const [add, setAdd] = useState(false);
    const [license, setLicense] = useState(initialLicense);
    const [update, setUpdate] = useState(false);


    const me = useSelector(state => state.auth.userAuthorized);
    const licensekeys = useSelector(state => state.license.licensekeys);

    const canEditLicenseKeys = PermissionLevel.canEditLicenseKeys(me)

    useEffect(() => {
        getLicenseKeys(customerId)(dispatch);
    }, [dispatch, update])

    const renderTable = () => {
        return (
            <Table className="user_permissions-table">
                <thead>
                    <tr>
                        <th>Beskrivning</th>
                        <th>Modultyper</th>
                        <th>Giltig till</th>
                        <th>Redigera</th>
                    </tr>
                </thead>
                {renderTableBody()}
            </Table>
        )
    }

    const renderTableBody = () => {
        const body = (info) => <tbody><tr><td>{info}</td></tr></tbody>

        if (licensekeys.status === StatusCode.PENDING)
            return (body(<Loading />));
        if (licensekeys.status === StatusCode.ERROR)
            return (body('Kunde inte hämta licensnycklar'));
        if (licensekeys.status === StatusCode.COMPLETE && licensekeys.data.licenseKeys.length === 0)
            return (body('Licensnycklar saknas'));
        return (
            <tbody>
                {licensekeys.status === StatusCode.COMPLETE && licensekeys.data.licenseKeys.map(lk =>
                    <tr key={lk.id}>
                        <td data-label="Beskrivning">{lk.description}</td>
                        <td data-label="Modultyper">{lk.licenseModules.map(lm => <span key={lm.id} className={!lm.isReleased ? 'warning' : ''}>{lm.title} {!lm.isReleased && '(Ej produktionssatt)'}</span>).reduce((prev, curr) => [prev, ', ', curr])}</td>
                        <td data-label="Giltig till">{Helper.formatDate(lk.validUntil)}</td>
                        {canEditLicenseKeys ? <td data-label="Redigera" className="edit-user" onClick={() => { setEdit(true); setLicense(lk) }}><FontAwesomeIcon icon="pen" /></td> : <td></td>}
                    </tr>
                )}
            </tbody>
        );
    }

    const renderEdit = () => {
        return (
            <EditLicenseKey
                license={license}
                add={add}
                isOpen={edit}
                toggleModal={() => { setEdit(false); setAdd(false); setLicense(initialLicense) }}
                disabled={false}
                updateLicense={() => setUpdate(!update)}
                customerId={customerId}
            />
        )
    }

    return (
        <div className="page licensekeys">
            <div>
                <Container>
                    <h1>Licensnycklar</h1>
                    <p className="title">{licensekeys.status === StatusCode.COMPLETE && licensekeys.data.title}</p>
                </Container>
            </div>
            <Container>
                {renderTable()}
                {canEditLicenseKeys && <Button className="btn-primary add_user-btn" onClick={() => { setEdit(true); setAdd(true) }}>Lägg till licensnyckel</Button>}

                {edit && renderEdit()}

            </Container >
        </div >
    )
}

export default LicenseKeys;
