import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { googleAuth, microsoftAuth } from './store/AuthAction';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { connect } from 'react-redux';
import Loading from './misc/Loading';
import { useCallback } from 'react';
import GoogleApi from './services/GoogleApi';

const SignInModal = ({ header, isOpen, toggleModal, googleAuth, microsoftAuth, authenticating }) => {

    var googleRef = useCallback(async elem => {
        await GoogleApi.renderButton(elem, async token => {
            await googleAuth(token);
        });
    });

    return (
        <Modal className="signin login" isOpen={isOpen} toggle={toggleModal}>
            <ModalHeader>{header}</ModalHeader>
            <ModalBody>
                <div className="login__logo-container">
                    <img className="login__logo" src="/FlowSync_lg_dark.svg" alt="FlowSync" />
                </div>
                <p>Välj inloggningsmetod</p>
                <div className="signin_btn_box">
                    <div className='login__provider--google' ref={googleRef}></div>
                    <div>
                        <button className="login__provider--microsoft" onClick={() => microsoftAuth()}>
                            <FontAwesomeIcon className="login__provider-icon" icon={['fab', 'microsoft']} /> Microsoft
                        </button>
                    </div>
                    {authenticating && <Loading />}
                </div>
            </ModalBody>
        </Modal>
    )
}

const mapStateToProps = state => ({
    authenticating: state.auth.authenticating,
});

export default connect(mapStateToProps, { googleAuth, microsoftAuth })(SignInModal);
