import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { Accordion, AccordionItem, AccordionHeader, AccordionBody, Table } from 'reactstrap';

const AppTokenConsentFeedback: React.FC = () => {

    const [searchParams, setSearchParams] = useSearchParams();
    const [open, setOpen] = useState('');
    const isError = searchParams.has("error"); 


    useEffect(() => {
        if (isError) {
            setOpen("errorinfo")
        }
    }, [isError])

    const toggleErrors = (id: string) => {
        if (open === id) {
            setOpen('');
        }
        else {
            setOpen(id);
        }
    };

    const consentFeedback = () => {
        const elements = Array.from(searchParams)
            .map(([key, value]) => <tr><td data-label="Parameter">{key}</td><td data-label="Värde">{value}</td></tr>);

        if (elements.length === 0) {
            return null;
        }


        return (
            <Accordion flush open={open} toggle={toggleErrors} className="app-token-consent__parameters" >
                <AccordionItem>
                    <AccordionHeader targetId="errorinfo">Felsökningsparametrar</AccordionHeader>
                    <AccordionBody accordionId="errorinfo">
                        <Table bordered>
                            <thead>
                                <tr>
                                    <th>Parameter</th>
                                    <th>Värde</th>
                                </tr>
                            </thead>
                            <tbody>
                                {elements}
                            </tbody>
                        </Table>
                    </AccordionBody>
                </AccordionItem>
            </Accordion>
        );
    };

    return (
        <div className="app-token-consent">
            <div className="app-token-consent__wrapper">
                <div className="app-token-consent__container">
                    <h2 className="app-token-consent__header">Tack för att ni använder FlowSync!</h2>
                    <p>
                        Behörighetsflödet är klart,
                        {isError ? <span> det ser ut som att något kan ha gått fel 🥹.</span> : <span> det går bra att lämna sidan.</span>}
                    </p>
                    {consentFeedback()}
                </div>
            </div>
    </div>);
}

export default AppTokenConsentFeedback;
