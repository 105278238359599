import React, { Component } from 'react';
import { Input, Col, Form, FormGroup } from 'reactstrap';

export class Search extends Component {

    updateData = (e) => {
        let value = e.target.value;
        this.props.onSearch(value)
    }

    render() {
        const props = this.props;
        return (
            <Col sm="6">
                <Form autoComplete="off" onSubmit={e => e.preventDefault()}>
                    <FormGroup className="search-form">
                        <Input type="text" name={props.name} disabled={props.disabled} value={props.value || ''} onChange={this.updateData} placeholder={props.placeholder} />
                    </FormGroup>
                </Form>
            </Col>
        )
    }
}

export default Search;
