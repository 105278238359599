import React from 'react';

const EdlevoActivities = () => {
    return (
        <div>
            <h1>Samarbetsytor för Edlevo</h1>
            <p>Det finns två olika sätt att läsa ut samarbetsytor från Edlevo, gruppbaserat eller aktivitetsbaserat. Dessa två olika utläsningar skiljer sig från varandra i hur de tolkar informationen från Edlevo. För att kunna beskriva hur dessa skiljer sig åt finns informationen nedan. Begreppet samarbetsytor är infört för att kunna prata om vad som vanligtvis blir Google Classroom, Microsoft Teams eller samarbetsyta i en lärplattform. </p>
            
            <h3>Justering av utläsningstyp</h3>
            <p>Om man ändrar denna inställning, framförallt under pågående läsår, kan vara problematiskt. Effekten av att ändra denna inställning är nämligen stor och svepande så det är därför viktigt att förstå konsekvensen av att ändra.</p>
            <p>Om man exempelvis ändrar från gruppbaserad utläsning till aktivitetsbaserat utläsning så kommer alla samarbetsytor som beror på grupper att arkiveras och tas bort. På samma sätt så gäller detsamma om man går från aktivitetsbaserad utläsning till gruppbaserad utläsning, effekten i det fallet skulle dock bli att alla samarbetsytor som beror på en läraraktivitet att arkiveras och tas bort. </p>
            <p>P.g.a. ovanstående effekt är vår rekommendation att man inte ändrar dessa inställningar under ett pågående läsår utan att dessa inställningar ändras i samband med ett läsårsskifte. Vill man ändå genomföra en förändring under pågående läsår är vår rekommendation att man under en visstid har båda typerna av utläsning aktiva samtidigt, man får dock som negativ effekt dubbletter av samarbetsytor om följd. </p>

            <h3>Gruppbaserad utläsning</h3>
            <p>Gruppbaserad utläsning utgår från klasser och grupper för att skapa samarbetsytor. För varje klass eller grupp i Edlevo kommer det att skapas en samarbetsyta, ingen läraraktivitet tas i beaktan av denna utläsning utan klassen eller gruppen blir en samarbetsyta med alla lärare och elever som medlemmar. Bilden nedan beskriver ett fiktivt exempel på klasser och grupper som läses ut från Edlevo. </p>
            <img src="./help-images/Gruppbaserad.png"/>

            <h3>Aktivitetsbaserad utläsning</h3>
            <p>Aktivitetsbaserad utläsning utgår också från klasser och grupper men tar hjälp av de läraraktiviteter (och dess ämnes eller kurskoppling) för att skapa samarbetsytor. För varje klass eller grupp så undersöks vilka läraraktiviteter som finns samt vilka ämnens eller kurser som finns. Alla lärare som har samma ämne eller kurs får en samarbetsyta för det ämnet eller kursen. Bilden nedan beskriver ett fiktivt exempel på klasser och grupper som läses ut från Edlevo. </p>
            <img src="./help-images/Aktivitetsbaserad.png"/>

            <p>När man använder sig av aktivitetsbaserad utläsning är det även viktigt att tänka på att man kan få upplevda dubbletter för ämnen och kurser om man har samma elever men läraraktiviteten är kopplad till en klass respektive en grupp.
                Något som exemplet med klassen <i>7A</i> och gruppen <i>7A Sv</i> indikerar och försöker belysa problematiken kring. </p>

        </div>
    )
}

export default EdlevoActivities;
