import HttpClient from '../services/HttpClient';
import StatusCode from '../util/StatusCode';

const GET_CUSTOMER_PERMISSIONS = 'GET_CUSTOMER_PERMISSIONS';
const GET_CUSTOMER_PERMISSIONS_LEVELS = 'GET_CUSTOMER_PERMISSIONS_LEVELS';

export const getCustomerPermissions = (customerId) => dispatch => {
    const url = `api/v1/customerpermission/${customerId}`;
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_CUSTOMER_PERMISSIONS,
                customerUsers: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_CUSTOMER_PERMISSIONS,
                customerUsers: 'error'
            })
        })
};

export const getCustomerPermissionsLevels = (customerId) => dispatch => {
    const url = `api/v1/permission/${customerId}/assignable`
    return HttpClient.get(url)
        .then(res => {
            dispatch({
                type: GET_CUSTOMER_PERMISSIONS_LEVELS,
                permissionLevels: res.data
            })
        })
        .catch(err => {
            dispatch({
                type: GET_CUSTOMER_PERMISSIONS_LEVELS,
                permissionLevels: 'error'
            })
        })
}

export const updateUserCustomerPermission = (customerId, user, allowUpdate) => () => {
    const url = `api/v1/customerpermission/${customerId}/user?allowUpdate=${allowUpdate}`;
    return updateCustomerPermissions(url, user);
}

export const updateGroupCustomerPermission = (customerId, group, allowUpdate) => () => {
    const url = `api/v1/customerpermission/${customerId}/group?allowUpdate=${allowUpdate}`;
    return updateCustomerPermissions(url, group);
}

const updateCustomerPermissions = async (url, items) => {
    try {
        await HttpClient.put(url, items);
        return { status: StatusCode.COMPLETE }
    } catch(err) {
        return { status: StatusCode.ERROR, error: err }
    }
}


const initialState = {
    customerUsers: undefined,
    permissionLevels: [],
    users: { status: StatusCode.NONE },
}

export const reducer = (state, action) => {
    state = state || initialState;

    switch (action.type) {
        case GET_CUSTOMER_PERMISSIONS:
            return {
                ...state,
                customerUsers: action.customerUsers,
            }
        case GET_CUSTOMER_PERMISSIONS_LEVELS:
            return {
                ...state,
                permissionLevels: action.permissionLevels,
            }
        default:
            return state;
    }
}