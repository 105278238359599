import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const propTypes = {
    onChangePage: PropTypes.func.isRequired,
    currentPage: PropTypes.number,
    totalPages: PropTypes.number
}

class Pagination extends Component {
    setPage(page) {
        const newPage = this.getNewPageNumber(page);

        if (this.isCurrentPage(newPage)) {
            return;
        }

        this.props.onChangePage(newPage);
    }

    getNewPageNumber(page) {
        const { totalPages } = this.props;

        if (page > totalPages) {
            return totalPages;
        } else if (page < 1) {
            return 1;
        } else {
            return page;
        }
    }

    getPageInterval(showPages) {
        const { totalPages, currentPage } = this.props;
        const interval = { start: 1, end: totalPages };

        const highHalf = Math.ceil(showPages / 2)
        const lowHalf = Math.floor(showPages / 2)

        // If there are more pages then [showPages] we need to calculate which to display.
        if (totalPages > showPages) {
            if (currentPage < lowHalf + 1) {
                interval.end = showPages;
            } else if (currentPage + lowHalf > totalPages) {
                interval.start = totalPages - (showPages - 1);
            } else {
                interval.start = currentPage - lowHalf;
                interval.end = currentPage + (highHalf - 1);
            }
        }

        return interval;
    }

    generatePages(interval, screenSize) {
        return Array.apply(0, Array(interval.end + 1 - interval.start)).map((_, index) => interval.start + index).map((page, index) => {
            const classNames = this.combineClassNamesIfCurrentPage(screenSize, 'active', page);
            return <li key={index} className={classNames} onClick={() => this.setPage(page)}>{page}</li>
        });
    }

    combineClassNamesIfCurrentPage(first, second, page) {
        const isCurrent = this.isCurrentPage(page);
        if (isCurrent && first) {
            return `${first} ${second}`;
        } else if (isCurrent) {
            return second;
        } else if (first) {
            return first;
        } else {
            return '';
        }
    }

    isCurrentPage(page) {
        return this.props.currentPage === page;
    }

    render() {
        const { totalPages, currentPage } = this.props;
        const desktopInterval = this.getPageInterval(9);
        const mobileInterval = this.getPageInterval(3);

        // Don't display pager if there is only 1 page
        if (totalPages <= 1) {
            return null;
        }

        return (
            <div className="pagination-box">
                <ul className="pagination">
                    <li className={this.combineClassNamesIfCurrentPage('showOnLg', 'disabled', 1) + ' nobreak'} onClick={() => this.setPage(1)}>
                        <FontAwesomeIcon icon="chevron-left" />
                        <FontAwesomeIcon icon="chevron-left" />
                    </li>
                    <li className={this.combineClassNamesIfCurrentPage(null, 'disabled', 1)} onClick={() => this.setPage(currentPage - 1)}>
                        <FontAwesomeIcon icon="chevron-left" />
                    </li>
                    { /*Small screens*/}
                    {this.generatePages(mobileInterval, 'hideOnLg')}

                    { /*Large screens*/}
                    {this.generatePages(desktopInterval, 'showOnLg')}

                    <li className={this.combineClassNamesIfCurrentPage(null, 'disabled', totalPages)} onClick={() => this.setPage(currentPage + 1)}>
                        <FontAwesomeIcon icon="chevron-right" />
                    </li>
                    <li className={this.combineClassNamesIfCurrentPage('showOnLg', 'disabled', totalPages) + ' nobreak'} onClick={() => this.setPage(totalPages)}>
                        <FontAwesomeIcon icon="chevron-right" />
                        <FontAwesomeIcon icon="chevron-right" />
                    </li>
                </ul>
                <p className="showOnLg pagination-text"><i>{`Visar sidor ${desktopInterval.start} - ${desktopInterval.end} av totalt ${totalPages} sidor.`}</i></p>
                <p className="hideOnLg pagination-text"><i>{`Visar sidor ${mobileInterval.start} - ${mobileInterval.end} av totalt ${totalPages} sidor.`}</i></p>
            </div>
        );
    }
}

Pagination.propTypes = propTypes;

export default Pagination;
